import React from 'react';
import { GridColDef, GridRenderEditCellParams, GridRowModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { get, isEmpty, set } from 'lodash';
import { Contact } from '../../../common/components/types';
import LookupGridField from '../../../common/list/LookupGridField';
import PersonListTile from '../../../common/components/person/PersonListTile';
import { Typography } from '@mui/material';
import { getContactInfoForPreview } from '../../../person/detailsPage/detailsPageUtilities/personBusinessContacts';

export const valueGetter = (params: GridValueGetterParams) => {
    if (isEmpty(params.row[params.field])) {
        return undefined;
    } else {
        return params.row[params.field];
    }
};

export const defaultOptionRenderer =
    (prop) =>
    ({ option }) => {
        const fontStyle = option.createOption ? 'italic' : 'normal';
        return (
            <Typography className="ellipsis" fontSize={'small'} fontStyle={fontStyle}>
                {get(option, prop)}
            </Typography>
        );
    };

export const defaultEntityToOption = (entity) => {
    return entity;
};

export const defaultFieldUpdater = (prop) => (colDefs, field, entity) => {
    const val = get(entity, prop);
    return [{ field: field, value: val }];
};

export const contactToOption = (contact) => {
    const names = contact.name.trim() ? contact.name.split(' ') : [];
    const primaryContact = contact.businessContacts?.find((contact: Contact) => contact.isPrimary);

    return {
        id: contact.id,
        name: {
            firstName: names[0],
            lastName: names.length > 1 ? names.slice(1).join(' ') : ''
        },
        contactInfo: primaryContact ? null : contact.contactInfo,
        personReference: contact.reference,
        organization: contact.organization,
        organizationReference: contact.organizationReference,
        role: contact.role,
        production: contact.production,
        productionReference: contact.productionReference,
        productionRole: contact.productionRole,
        organizationDepartment: contact.department,
        title: contact.title,
        productionDepartment: contact.productionDepartment
    };
};

export const organizationToOption = (organization) => {
    return {
        organization: organization.name,
        organizationReference: organization.reference
    };
};

export const productionToOption = (production) => {
    return {
        production: production.name,
        productionReference: production.reference
    };
};

export const contactToUpdate = (colDefs, field, entity) => {
    const update = [];
    colDefs.forEach((col) => {
        const val = get(entity, col.field);
        update.push({
            field: col.field,
            value: val
        });
    });
    return update;
};

export const organizationToUpdate = (colDefs, field, entity) => {
    return [
        { field: 'organization', value: get(entity, 'organization') },
        { field: 'organizationReference', value: get(entity, 'organizationReference') }
    ];
};

export const productionToUpdate = (colDefs, field, entity) => {
    return [
        { field: 'production', value: get(entity, 'production') },
        { field: 'productionReference', value: get(entity, 'productionReference') }
    ];
};

export const firstName: GridColDef = {
    field: 'name.firstName',
    headerName: 'First name',
    flex: 1,
    sortable: true,
    editable: true,
    minWidth: 120,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Person' }]}
                OptionRenderer={PersonListTile}
                toOption={contactToOption}
                entityToUpdate={contactToUpdate}
                filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
            />
        );
    }
};

export const lastName: GridColDef = {
    field: 'name.lastName',
    headerName: 'Last name',
    flex: 1,
    sortable: true,
    editable: true,
    minWidth: 120,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Person' }]}
                OptionRenderer={PersonListTile}
                toOption={contactToOption}
                entityToUpdate={contactToUpdate}
                filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
            />
        );
    }
};
export const organization: GridColDef = {
    field: 'organization',
    headerName: 'Organization',
    flex: 1,
    sortable: true,
    editable: true,
    type: 'string',
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Organization' }]}
                OptionRenderer={defaultOptionRenderer('organization')}
                toOption={organizationToOption}
                entityToUpdate={organizationToUpdate}
            />
        );
    }
};
export const email: GridColDef = {
    field: 'contactInfo.email.address',
    headerName: 'Email',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Person' }]}
                OptionRenderer={PersonListTile}
                toOption={contactToOption}
                entityToUpdate={contactToUpdate}
                filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
            />
        );
    }
};

export const phone: GridColDef = {
    field: 'contactInfo.phone.number',
    headerName: 'Phone',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter
};

export const title: GridColDef = {
    field: 'title',
    headerName: 'Title',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Title' }]}
                OptionRenderer={defaultOptionRenderer('name')}
                toOption={defaultEntityToOption}
                entityToUpdate={defaultFieldUpdater('name')}
            />
        );
    }
};
export const department: GridColDef = {
    field: 'organizationDepartment',
    headerName: 'Department',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Department' }]}
                OptionRenderer={defaultOptionRenderer('name')}
                toOption={defaultEntityToOption}
                entityToUpdate={defaultFieldUpdater('name')}
            />
        );
    }
};
export const role: GridColDef = {
    field: 'role',
    headerName: 'Professional Role',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Role' }]}
                OptionRenderer={defaultOptionRenderer('name')}
                toOption={defaultEntityToOption}
                entityToUpdate={defaultFieldUpdater('name')}
            />
        );
    }
};
export const production: GridColDef = {
    field: 'production',
    headerName: 'Production',
    flex: 1,
    sortable: true,
    editable: true,
    type: 'string',
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Production' }]}
                OptionRenderer={defaultOptionRenderer('production')}
                toOption={productionToOption}
                entityToUpdate={productionToUpdate}
            />
        );
    }
};
export const productionRole: GridColDef = {
    field: 'productionRole',
    headerName: 'Production Role',
    flex: 0.75,
    sortable: true,
    editable: true,
    type: 'string',
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Role' }]}
                OptionRenderer={defaultOptionRenderer('name')}
                toOption={defaultEntityToOption}
                entityToUpdate={defaultFieldUpdater('name')}
            />
        );
    }
};

export const productionDepartment: GridColDef = {
    field: 'productionDepartment',
    headerName: 'Production Department',
    flex: 0.75,
    sortable: true,
    editable: true,
    minWidth: 80,
    valueGetter: valueGetter,
    renderEditCell: (params: GridRenderEditCellParams) => {
        return (
            <LookupGridField
                {...params}
                columns={colDefs}
                filters={[{ identifier: 'entity', value: 'Department' }]}
                OptionRenderer={defaultOptionRenderer('name')}
                toOption={defaultEntityToOption}
                entityToUpdate={defaultFieldUpdater('name')}
            />
        );
    }
};

const fullName: GridColDef = {
    field: 'fullName',
    headerName: 'Full name',
    flex: 1,
    sortable: true
};

export const personReference: GridColDef = {
    field: 'personReference',
    headerName: 'Person Reference',
    flex: 1,
    sortable: true,
    editable: true,
    hideable: false,
    valueGetter: valueGetter
};

export const productionReference: GridColDef = {
    field: 'productionReference',
    headerName: 'Production Reference',
    flex: 1,
    sortable: true,
    editable: true,
    hideable: false,
    valueGetter: valueGetter
};

export const organizationReference: GridColDef = {
    field: 'organizationReference',
    headerName: 'Organization Reference',
    flex: 1,
    sortable: true,
    editable: true,
    hideable: false,
    valueGetter: valueGetter
};

export const colDefs: GridColDef[] = [
    firstName,
    lastName,
    organization,
    email,
    phone,
    title,
    department,
    role,
    production,
    productionRole,
    productionDepartment,
    personReference,
    productionReference,
    organizationReference
];

export const columnVisibility = {
    personReference: false,
    productionReference: false,
    organizationReference: false
};

export const altColDefs: GridColDef[] = [
    fullName,
    organization,
    email,
    phone,
    title,
    department,
    role,
    production,
    productionRole,
    productionDepartment
];

export const contactsToRows = (contacts: Contact[]) => {
    return contacts
        ? contacts.map((contact, index) => {
              const row = { id: index };
              colDefs.forEach((col) => {
                  row[col.field] = get(contact, col.field);
              });
              row['_raw'] = contact;
              return row;
          })
        : [];
};
export const rowsToContacts = (rows: GridRowModel[], colDefs: GridColDef[]): Contact[] => {
    return rows.map((row) => {
        return rowToContact(row, colDefs);
    });
};

export const rowToContact = (row: GridRowModel, colDefs: GridColDef[]): Contact => {
    const contact: Contact = {};
    set(contact, 'id', row.id);
    set(contact, 'personReference', row.reference);
    colDefs.forEach((col) => {
        // Small hack to deal with full name vs first/last name.
        if (col.field === 'fullName') {
            const fullName = row.fullName;
            const space = fullName.indexOf(' ');
            const firstName = fullName.substring(0, space);
            const lastName = fullName.substring(space + 1);
            set(contact, 'name.firstName', firstName);
            set(contact, 'name.lastName', lastName);
        } else {
            set(contact, col.field, row[col.field]);
        }
    });
    if (get(contact, 'contactInfo.email.address')) {
        set(contact, 'contactInfo.email.typeString', 'Business');
        set(contact, 'contactInfo.email.verified', false);
    } else {
        set(contact, 'contactInfo.email', null);
    }
    if (get(contact, 'contactInfo.phone.number')) {
        set(contact, 'contactInfo.phone.typeString', 'Mobile');
    } else {
        set(contact, 'contactInfo.phone', null);
    }
    return contact;
};
