/** @jsxImportSource @emotion/react */

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import type { Klass, LexicalNode } from 'lexical';
import { $createParagraphNode, $createTextNode, $getRoot, EditorState, LexicalEditor } from 'lexical';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { V } from '../../../common/Layout';
import { useStyling } from '../../../common/Theme';
import { Contact } from '../../../common/components/types';
import ContactOptionRenderer from '../../../person/ContactOptionRenderer';
import CodeHighlightPlugin from './CodeHighlightPlugin';
import LexicalBottomToolbar from './LexicalBottomToolbar';
import { lexicalTheme } from './LexicalEditorTheme';
import LexicalTopToolbar from './LexicalTopToolbar';
import { MentionsNode } from './MentionsNode';
import MentionsPlugin, { MentionOption } from './MentionsPlugin';
import useEditorFocus from './useEditorFocus';
import { useLexicalIsTextContentEmpty } from './useLexicalIsTextContentEmpty';

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus();
    }, [editor]);

    return null;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
    console.error(error);
}

const URL_MATCHER =
    /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const EMPTY_EDITOR_STATE =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const MATCHERS = [
    (text) => {
        const match = URL_MATCHER.exec(text);
        if (match === null) {
            return null;
        }
        const fullMatch = match[0];
        return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
            attributes: { rel: 'noopener', target: '_blank' } // Optional link attributes
        };
    }
];

const AVAILABLE_NODES: Array<Klass<LexicalNode>> = [
    AutoLinkNode,
    CodeHighlightNode,
    CodeNode,
    HashtagNode,
    HeadingNode,
    HorizontalRuleNode,
    LinkNode,
    ListItemNode,
    ListNode,
    MarkNode,
    MentionsNode,
    OverflowNode,
    QuoteNode,
    TableCellNode,
    TableNode,
    TableRowNode
];

const Placeholder = ({ text, color }) => {
    const { theme } = useStyling();
    return (
        <Typography
            sx={{
                color: color,
                overflow: 'hidden',
                position: 'absolute',
                textOverflow: 'ellipsis',
                top: '44px',
                left: '8px',
                right: '28px',
                userSelect: 'none',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                pointerEvents: 'none',
                fontSize: '14px'
            }}
        >
            {text}
        </Typography>
    );
};

const setEditorState = (editor, content, getTextFromContent) => {
    try {
        if (typeof content === 'string') {
            if (content.includes('"root"')) {
                const json = JSON.parse(content);
                const editorState: EditorState = editor.parseEditorState(json);
                if (getTextFromContent) {
                    const editorStateTextString = editorState.read(() => $getRoot().getTextContent());
                    getTextFromContent(editorStateTextString);
                }

                editor.setEditorState(editorState);
            } else {
                const text = $createTextNode(content);
                const paragraph = $createParagraphNode();
                const root = $getRoot();
                paragraph.append(text);
                root.append(paragraph);
            }
        }
    } catch (err) {
        console.log('Failed to initialize Lexical editor content', err);
    }
};

// const maskCss = 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x';

const findFirstTextLine = (data) => {
    const queue = [data];

    while (queue.length > 0) {
        const currentNode = queue.shift();

        if (currentNode.type === 'text') {
            return currentNode.text;
        }

        if (currentNode.children) {
            queue.push(...currentNode.children);
        }
    }
};

export const Editor = ({
    content,
    id,
    isSmall,
    readOnly,
    onSave,
    onChange,
    onCancel,
    onDelete,
    collapsed,
    contentPlaceholder,
    clearOnSave,
    clearOnCancel,
    header,
    footer,
    getTextFromContent,
    isEditing
}) => {
    const [editor] = useLexicalComposerContext();
    const contentFocused = useEditorFocus();
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { theme, isDarkMode } = useStyling();
    const placeholderColor = theme.palette.text.disabled;
    const isEmpty = useLexicalIsTextContentEmpty(editor);

    const containerSx: SxProps = useMemo(() => {
        return readOnly
            ? {
                  gap: 0.5,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  position: 'relative',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  '&:hover': {
                      backgroundColor: theme.palette.grey[isDarkMode ? 700 : 100]
                  },
                  p: 1
              }
            : {
                  gap: 0.5,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  position: 'relative',
                  border: `2px solid ${contentFocused ? theme.palette.primary.main : theme.palette.divider}`,
                  borderRadius: 1,
                  minHeight: '140px',
                  height: '100%'
              };
    }, [readOnly, theme, contentFocused]);

    const editorStyle = useMemo(() => {
        return {
            flexGrow: 2,
            width: '100%',
            paddingLeft: readOnly ? 0 : '8px',
            paddingRight: readOnly ? 0 : '8px',
            overflow: 'auto'
        };
    }, [readOnly]);

    useEffect(() => {
        if (editor) {
            editor.setEditable(!readOnly);
        }
    }, [editor, readOnly]);

    const clearState = useCallback(() => {
        if (editor) {
            const editorState: EditorState = editor.parseEditorState(EMPTY_EDITOR_STATE);
            editor.setEditorState(editorState);
        }
    }, [editor]);

    useEffect(() => {
        if (content && editor) {
            setEditorState(editor, content, getTextFromContent);
            editor.focus();
        }
    }, [content, editor]);

    return (
        <V className={'_lexicalContainer'} sx={containerSx}>
            {header}
            {readOnly ? <Fragment /> : <LexicalTopToolbar isSmall={isSmall} disabled={readOnly /*!contentFocused*/} />}
            {collapsed && !isEditing ? (
                <></>
            ) : (
                <RichTextPlugin
                    contentEditable={<ContentEditable className={'lexical'} style={editorStyle} />}
                    placeholder={
                        readOnly ? (
                            <React.Fragment />
                        ) : (
                            <Placeholder text={contentPlaceholder} color={placeholderColor} />
                        )
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
            )}
            <OnChangePlugin
                ignoreSelectionChange
                onChange={(editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => {
                    if (typeof onChange === 'function') {
                        // const selection = $getSelection();
                        editorState.read(() => {
                            // Read the contents of the EditorState here.
                            // const root = $getRoot();
                            const json = editorState.toJSON();
                            onChange(JSON.stringify(json));
                        });
                        if (getTextFromContent) {
                            const editorStateTextString = editorState.read(() => $getRoot().getTextContent());
                            getTextFromContent(editorStateTextString);
                        }
                    }
                }}
            />
            <HistoryPlugin />
            <AutoLinkPlugin matchers={MATCHERS} />
            <MentionsPlugin
                id={`lexical-mentions-plugin`}
                trigger={'@'}
                entity={'Person'}
                rowToOption={(row, index) => {
                    const names = row.name ? row.name.split(' ') : [];
                    const contact: Contact = {
                        id: row.id,
                        name: {
                            firstName: names[0],
                            lastName: names.length > 1 ? names.slice(1).join(' ') : ''
                        },
                        contactInfo: row.contactInfo,
                        personReference: row.reference,
                        organization: row.organization,
                        role: row.role,
                        production: row.production,
                        productionRole: row.productionRole
                    };
                    return new MentionOption<Contact>(
                        contact.id,
                        row.name,
                        contact.contactInfo?.email?.address,
                        contact,
                        <i />
                    );
                }}
                OptionRenderer={ContactOptionRenderer}
            />
            <ListPlugin />
            <CheckListPlugin />
            <CodeHighlightPlugin />
            {/*<MyCustomAutoFocusPlugin />*/}
            {readOnly || (!onSave && !onCancel && !onDelete) ? (
                <Fragment />
            ) : (
                <LexicalBottomToolbar
                    onSave={
                        typeof onSave === 'function'
                            ? () => {
                                  if (!isEmpty) {
                                      const newValue = editor.getEditorState().toJSON();
                                      const title = findFirstTextLine(newValue.root) || '';
                                      setIsSaving(true);
                                      return onSave(title, JSON.stringify(newValue)).then(() => {
                                          setIsSaving(false);
                                          if (clearOnSave) {
                                              clearState();
                                          }
                                      });
                                  }
                              }
                            : undefined
                    }
                    isSaveDisabled={isEmpty}
                    isSaving={isSaving}
                    onDelete={
                        typeof onDelete === 'function'
                            ? () => {
                                  setIsDeleting(true);
                                  return onDelete(id).then(() => {
                                      setIsDeleting(false);
                                  });
                              }
                            : undefined
                    }
                    isDeleteDisabled={false}
                    isDeleting={isDeleting}
                    onCancel={
                        typeof onCancel === 'function'
                            ? () => {
                                  return onCancel().then(() => {
                                      if (clearOnCancel) {
                                          clearState();
                                      }
                                  });
                              }
                            : undefined
                    }
                />
            )}
            {footer}
        </V>
    );
};

export default ({
    id,
    content,
    onChange = undefined,
    onSave = undefined,
    onCancel = undefined,
    onDelete = undefined,
    readOnly = false,
    collapsed = false,
    isSmall = false,
    clearOnSave = false,
    clearOnCancel = false,
    contentPlaceholder = 'Content',
    header = <Fragment />,
    footer = <Fragment />,
    getTextFromContent = undefined,
    isEditing = false
}) => {
    const { theme } = useStyling();
    const initialConfig: InitialConfigType = React.useMemo(() => {
        return {
            namespace: id,
            editable: !readOnly,
            editorState: (editor: LexicalEditor) => setEditorState(editor, content, getTextFromContent),
            theme: lexicalTheme(theme),
            onError,
            nodes: [...AVAILABLE_NODES]
        };
    }, [readOnly, content]);

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <Editor
                id={id}
                content={content}
                readOnly={readOnly}
                onChange={onChange}
                onSave={onSave}
                onCancel={onCancel}
                onDelete={onDelete}
                collapsed={collapsed}
                isSmall={isSmall}
                clearOnSave={clearOnSave}
                clearOnCancel={clearOnCancel}
                contentPlaceholder={contentPlaceholder}
                header={header}
                footer={footer}
                getTextFromContent={getTextFromContent}
                isEditing={isEditing}
            />
        </LexicalComposer>
    );
};
