import { useQuery } from '@apollo/client';
import { Button, IconButton, Typography } from '@mui/material';
import { Filter } from '@searchkit/client';
import { isEmpty, isEqual, remove } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FacetsList } from '../../supply/searchkit/facets/FacetsList';
import FacetsPanel from '../../supply/searchkit/facets/FacetsPanel';
import { UserContext } from '../auth/UserContext';
import { ActionType } from '../components/ActionType';
import AddActionsFab from '../components/AddActionsFab';
import BreadCrumbs from '../components/BreadCrumbs';
import Confirm from '../components/Confirm';
import { SortModel, UNSET_SORT, usePersistedSearchkitVariables } from '../components/filtering/filters';
import { constants } from '../constants';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import { Conditional, H, V } from '../Layout';
import useHistory from '../utils/useHistory';
import ViewTypeSelector, { View } from './ViewTypeSelector';
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import useDefaultFilters from '../hooks/useDefaultFilters';

export default ({
    id,
    entity,
    feature,
    uri = undefined,
    query,
    EntityListRenderer = undefined,
    EntityTableRenderer = undefined,
    EntityGalleryRenderer = undefined,
    EntityScheduleRenderer = undefined,
    EntityEditor = undefined,
    EntityDetails = undefined,
    ImportView = undefined,
    preFilters,
    immutableFilters = undefined,
    showFacets = true,
    enableImport = false,
    enableImportIcon = false,
    enableSearch = false,
    addActions = [] as ActionType[],
    editDisabled = false,
    defaultSortModel = { field: 'name', sort: 'asc' } as SortModel,
    promoteFacets = undefined,
    showBreadCrumbs = true,
    containerSx = undefined,
    saveFiltersDisabled = false,
    notifyOnNetworkStatusChange = false,
    enableSearchLoader = false,
    facetsVisibility = { filters: true, searchPanel: true, promotedFacets: true, quickFilter: true },
    quickFilters = [],
    searchPlaceholder = undefined,
    initialSearchQuery = undefined,
    searchQuery = undefined,
    defaultView = undefined,
    createUri = false,
    filtersWithSameIdentifier = [],
    defaultFilters = [],
    ...props
}) => {
    const { routeParams, changeRoute, searchParams, changeSearch } = useHistory();
    const { entityId } = routeParams;
    const [listLoading, setListLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [importPanelOpen, setImportPanelOpen] = useState(false);
    const editMode = !!entityId;
    const [detailsVisible, setDetailsVisible] = useState(editMode);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [activeEntity, setActiveEntity] = useState('');
    const { isAuthenticated, activeOrganizationAccount } = useContext(UserContext);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(undefined);

    const { variables, sortModel, setSortModel, pageNumber, setPageNumber, pageSize, setPageSize } =
        usePersistedSearchkitVariables(id, {
            saveFiltersDisabled,
            defaultSortField: `${defaultSortModel.field}_${defaultSortModel.sort}`
        });

    useEffect(() => {
        if (initialSearchQuery) {
            variables.query = initialSearchQuery;
            setPageNumber(0);
            doRefetch();
        }
    }, []);

    useEffect(() => {
        if (searchQuery) {
            variables.query = searchQuery;
            setPageNumber(0);
            doRefetch();
        }
    }, [searchQuery]);

    useEffect(() => {
        setDetailsVisible(!!entityId);
    }, [entityId]);

    useEffect(() => {
        if (!isEmpty(immutableFilters)) {
            immutableFilters.forEach((preFilter: Filter) => {
                remove(variables.filters, { identifier: preFilter.identifier });
                variables.filters.push(preFilter);
            });
        }
    }, [variables, immutableFilters]);

    useEffect(() => {
        if (!isEmpty(filtersWithSameIdentifier)) {
            const removeFilterByIdentifierAndValue = (identifier, value) => {
                remove(variables.filters, (filter) => filter.identifier === identifier && filter.value === value);
            };

            filtersWithSameIdentifier.forEach((filter) => {
                removeFilterByIdentifierAndValue(filter.identifier, filter.value);
                variables.filters.push(filter);
            });
        }
    }, [variables, filtersWithSameIdentifier]);

    if (defaultFilters?.length) {
        useDefaultFilters(id, defaultFilters);
    }

    useEffect(() => {
        if (!isEmpty(preFilters)) {
            preFilters.forEach((preFilter: Filter) => {
                remove(variables.filters, { identifier: preFilter.identifier });
                variables.filters.push(preFilter);
            });
        }
    }, [preFilters]);

    const onEdit = (id) => {
        changeRoute(`${uri}/${id}`);
    };

    const onDelete = (id) => {
        setActiveEntity(id);
        setDeleteConfirmationOpen(true);
    };

    const onEditorClose = () => {
        setEditorOpen(false);
        setActiveEntity('');
        changeRoute(uri);
    };

    const onImportPanelClose = () => {
        setImportPanelOpen(false);
    };

    const { data, loading, refetch, error } = useQuery(query, {
        variables,
        skip: variables.filters.length === 0,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: notifyOnNetworkStatusChange
    });
    const Facets = FacetsList([], loading);

    const doRefetch = () => {
        if (isAuthenticated) {
            // Sorting and pagination.
            variables.sortBy = '';
            if (sortModel) {
                const field = sortModel.field;
                const order = sortModel.sort.toUpperCase();
                variables.sortBy = field + '_' + order;
            }
            variables.page = { size: pageSize, from: pageNumber * pageSize };

            // Remove and add entity.
            remove(variables.filters, { identifier: 'entity' });
            variables.filters.push({ identifier: 'entity', value: entity });

            setListLoading(true);
            refetch(variables)
                .then(() => {})
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setListLoading(false);
                    setIsSearching(false);
                });
        }
    };

    const prevFilters = useRef(variables.filters);

    const resetPagination = () => {
        setPageNumber(0);
        setPaginationModel({
            page: 0,
            pageSize
        });
    };
    useDeepCompareEffect(() => {
        if (!isEqual(variables.filters, prevFilters.current)) {
            resetPagination();
            prevFilters.current = variables.filters;
        }
    }, [variables.filters]);

    useEffect(doRefetch, [pageSize, pageNumber]);

    useDeepCompareEffect(doRefetch, [preFilters, sortModel, immutableFilters]);

    const defaultViews: View[] = [];
    if (EntityTableRenderer) {
        defaultViews.push({
            icon: Icons.ListViewList,
            label: 'Table',
            Component: EntityTableRenderer,
            showFacets: true
        });
    }
    if (EntityListRenderer) {
        defaultViews.push({
            icon: Icons.CardListIcon,
            label: 'List',
            Component: EntityListRenderer,
            showFacets: true
        });
    }
    if (EntityGalleryRenderer) {
        defaultViews.push({
            icon: Icons.ListViewGallery,
            label: 'Gallery',
            Component: EntityGalleryRenderer,
            showFacets: true
        });
    }
    if (EntityScheduleRenderer) {
        defaultViews.push({
            icon: Icons.Schedule,
            label: 'Schedule',
            Component: EntityScheduleRenderer,
            showFacets: false
        });
    }

    const [views, setViews] = useState<View[]>(defaultViews);
    const currentView = searchParams.get(`${id}_${constants.viewKey}`);
    const [selectedView, setSelectedView] = useState(0);

    useEffect(() => {
        const selectedViewIndex = views.findIndex((view) => view.label === currentView);
        const defaultViewIndex = defaultView && !currentView && views.findIndex((view) => view.label === defaultView);

        if (defaultViewIndex) {
            changeSearch({ [`${id}_${constants.viewKey}`]: defaultView });
        } else if (selectedViewIndex === -1) {
            changeSearch({ [`${id}_${constants.viewKey}`]: activeView?.label });
        } else {
            setSelectedView(selectedViewIndex);
        }
    }, [views, changeSearch, currentView, id]);

    useEffect(() => {
        if (!loading && error) {
            console.error('Apollo error:', error);
        }
    }, [loading, error]);

    useEffect(() => {
        if (entityId) {
            setActiveEntity(entityId);
            setEditorOpen(true);
        }
    }, [entityId]);

    const activeView = views[selectedView];
    const hasFacets = showFacets && activeView && activeView.showFacets;
    // const currentRoutePerms = getFeaturePermissions(
    //     userProfile,
    //     application,
    //     activeOrganizationAccount,
    //     currentRoute.feature
    // );
    // const canCreate = currentRoutePerms[AllPermissions.Manage];
    const displayAddActions =
        activeView && !activeView.disableAddActions && (addActions.length > 0 || EntityEditor || createUri);

    return (
        <React.Fragment>
            <V className="ListView" sx={{ overflow: 'hidden', p: 1, gap: 1, ...containerSx }}>
                {showBreadCrumbs && <BreadCrumbs uri={uri} />}
                {(hasFacets || enableImport || views.length > 1 || displayAddActions) && (
                    <H>
                        <H sx={{ gap: 1 }}>
                            {displayAddActions && (
                                <AddActionsFab
                                    addActions={addActions}
                                    defaultAddAction={() => {
                                        if (createUri) {
                                            changeRoute(`${uri}/${constants.createId}`);
                                        } else if (EntityEditor) {
                                            setActiveEntity(constants.createId);
                                            setEditorOpen(true);
                                        }
                                    }}
                                />
                            )}
                            {enableImport && (
                                <IconButton onClick={() => setImportPanelOpen(true)} color={'primary'}>
                                    <Icons.FileUpload />
                                </IconButton>
                            )}
                            {hasFacets && (
                                <FacetsPanel
                                    id={id}
                                    Facets={Facets}
                                    data={data}
                                    loading={loading}
                                    promote={promoteFacets}
                                    facetsVisibility={facetsVisibility}
                                    searching={enableSearchLoader && isSearching}
                                    searchPlaceholder={searchPlaceholder}
                                    onSearch={
                                        enableSearch
                                            ? (query) => {
                                                  setIsSearching(true);
                                                  variables.query = query;
                                                  setPageNumber(0);
                                                  doRefetch();
                                              }
                                            : undefined
                                    }
                                    quickFilters={quickFilters}
                                    immutableFilters={immutableFilters}
                                />
                            )}
                        </H>
                        {views.length > 1 && (
                            <ViewTypeSelector
                                views={views}
                                selectedViewIndex={selectedView}
                                setSelectedViewIndex={setSelectedView}
                                id={id}
                            />
                        )}
                    </H>
                )}
                {activeView && (
                    <H className="ListView_container" sx={{ height: '100%', overflow: 'hidden' }}>
                        <activeView.Component
                            id={id}
                            entity={entity}
                            feature={feature}
                            data={data}
                            loading={loading || listLoading}
                            searching={isSearching}
                            paginationModel={paginationModel}
                            refetch={refetch}
                            setListLoading={setListLoading}
                            onEdit={EntityEditor && !editDisabled ? onEdit : undefined}
                            onDelete={props.onDelete ? onDelete : undefined}
                            page={pageNumber}
                            onPageChange={(pageNumber, details) => {
                                setPaginationModel({
                                    page: pageNumber,
                                    pageSize
                                });
                                setPageNumber(pageNumber);
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(pageSize) => {
                                setPaginationModel({
                                    page: pageNumber,
                                    pageSize
                                });
                                setPageSize(pageSize);
                            }}
                            sortModel={sortModel ? [sortModel] : []}
                            onSortModelChange={(newSortModel) => {
                                if (!isEqual(sortModel, newSortModel)) {
                                    setPageNumber(0);
                                    if (!isEmpty(newSortModel)) {
                                        setSortModel(newSortModel[0]);
                                    } else {
                                        setSortModel({ field: UNSET_SORT, sort: UNSET_SORT });
                                    }
                                }
                            }}
                            uri={uri}
                        />
                    </H>
                )}
            </V>
            {ImportView && (
                <ImportView onSave={doRefetch} importPanelOpen={importPanelOpen} onClose={onImportPanelClose} />
            )}
            {EntityEditor && editorOpen && (
                <EntityEditor id={activeEntity} editorOpen={editorOpen} onClose={onEditorClose} onSave={doRefetch} />
            )}
            {detailsVisible && EntityDetails ? (
                <EntityDetails
                    id={activeEntity}
                    onSave={doRefetch}
                    onClose={(redirect) => {
                        setDetailsVisible(false);
                        if (redirect) {
                            changeRoute(redirect);
                        }
                    }}
                />
            ) : (
                <React.Fragment />
            )}
            <Conditional condition={props.onDelete}>
                <Confirm
                    open={deleteConfirmationOpen}
                    title={<I18n token={'delete.confirm.title'} />}
                    okLabelI18n="delete.confirm.title"
                    body={
                        <Typography>
                            <I18n token={'delete.confirm.listView.message'} />
                        </Typography>
                    }
                    onConfirm={() => {
                        setDeleteConfirmationOpen(false);
                        Promise.resolve(props.onDelete(activeEntity)).then(doRefetch);
                    }}
                    onCancel={() => {
                        setDeleteConfirmationOpen(false);
                    }}
                />
            </Conditional>
        </React.Fragment>
    );
};
