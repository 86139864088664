import React, { useEffect, useMemo, useState } from 'react';
import FormModal from '../../../common/form/FormModal';
import FormPanel from '../../../common/form/FormPanel';
import I18n from '../../../common/i18n/I18n';
import Loading from '../../../common/components/Loading';
import OpportunityResponsePreview from './OpportunityResponsePreview';
import {
    createProductEntityFilters,
    createSaveInquiryResponse,
    extractCombinations,
    handleInquiryResponse,
    inquiryResponseStatuses,
    useGetInquiryStatusDetails,
    useGetProductData,
    useSaveInquiryResponse
} from '../opportunityInquiry';
import { FormProvider } from '../../../common/form/FormContext';
import { failedToastMessage } from '../../../common/utils/commonUtils';
import { ValidationRulesType } from '../../../production/helpers/productionUtils';
import Validator from '../../../common/form/Validator';

export default ({
    bookingId,
    opportunity, // for active users
    setOpenModal,
    openModal,
    setThankYouVisible = undefined, // for inactive users
    setThankYouName = undefined, // for inactive users
    token = undefined // for inactive users
}) => {
    const { saveOpportunityInquiryResponse, isSaving } = useSaveInquiryResponse(token);
    const [dynamicValidationRules, setDynamicValidationRules] = useState<ValidationRulesType>({});
    const [state, setState] = useState(undefined);
    const [requestNoteLabel, setRequestNoteLabel] = useState(undefined);

    const opportunityResourceObject = opportunity?.productionRequirements;
    const productEntityFilters = useMemo(() => {
        if (opportunity) {
            const extractedCombinations = extractCombinations(opportunityResourceObject);
            return createProductEntityFilters(extractedCombinations);
        }
    }, [opportunityResourceObject]);

    const { productData = null } = opportunity ? useGetProductData(productEntityFilters, false) : {};
    const { inquiryStatusData } = useGetInquiryStatusDetails(bookingId, token);

    useEffect(() => {
        const status = state && state.status && state.status.id;
        if (status === inquiryResponseStatuses.requestInfo) {
            setDynamicValidationRules({ requestNote: [Validator.RULES.isRequired] });
        } else {
            setDynamicValidationRules({});
        }
    }, [state?.status?.id]);

    const onSaveResponse = () => {
        const inquiryResponseObject = createSaveInquiryResponse(inquiryStatusData?.inquiryStatus?.inquiryId, state);
        saveOpportunityInquiryResponse(inquiryResponseObject)
            .then(
                (res: any) =>
                    !setThankYouVisible &&
                    handleInquiryResponse(res, inquiryStatusData?.inquiryStatus?.inquiryMessage?.senderInfo?.name)
            )
            .catch((err) => failedToastMessage('form.shortlistLineItem.inquiry.error'))
            .finally(() => {
                handleCloseModal();
                if (setThankYouVisible) {
                    setThankYouVisible(true);
                    setThankYouName(inquiryStatusData?.inquiryStatus?.inquiryMessage?.senderInfo?.name);
                }
            });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setRequestNoteLabel(undefined);
    };

    return (
        <FormModal
            id={'form-modal-opportunity-response-id'}
            title={
                opportunity ? (
                    <I18n token={'form.respond.modal'} values={{ name: opportunity.organization.name }} />
                ) : (
                    ''
                )
            }
            isOpen={openModal}
            saveLabel={<I18n token={'form.button.save'} />}
            isSaveDisabled={
                state?.status?.id === inquiryResponseStatuses.requestSent ||
                (state?.status?.id === inquiryResponseStatuses.requestInfo && !state?.requestNote) ||
                inquiryStatusData?.inquiryStatus?.status === inquiryResponseStatuses.yes ||
                inquiryStatusData?.inquiryStatus?.status === inquiryResponseStatuses.no
            }
            onSave={onSaveResponse}
            isSaving={isSaving}
        >
            {inquiryStatusData ? (
                <FormProvider state={state} setState={setState} validationRules={dynamicValidationRules}>
                    <FormPanel>
                        <OpportunityResponsePreview
                            inquiryStatusData={inquiryStatusData?.inquiryStatus}
                            numberOfProducts={productData?.search?.hits?.items?.length}
                            requestNoteLabel={requestNoteLabel}
                            setRequestNoteLabel={setRequestNoteLabel}
                        />
                    </FormPanel>
                </FormProvider>
            ) : (
                <Loading />
            )}
        </FormModal>
    );
};
