import { SearchkitClient, useSearchkit } from '@searchkit/client';
import QuickFilterList from '../../../common/components/filtering/QuickFilterList';
import { useMemo } from 'react';
import React from 'react';

const getFilter = (api: SearchkitClient, identifier: string) => {
    const filters = api.getFiltersByIdentifier(identifier).map((filter) => filter.value);
    return filters || [];
};

export default ({ facets = [], quickFilters }) => {
    const api = useSearchkit();

    const newFilters = useMemo(() => {
        return (
            facets &&
            facets.length &&
            facets
                .filter((facet) => quickFilters.some((quickFilter) => quickFilter.identifier === facet.identifier))
                .map((facet) => {
                    const quickFilter = quickFilters.find((quickFilter) => quickFilter.identifier === facet.identifier);
                    const filterValues = getFilter(api, facet.identifier);
                    const filter = {
                        id: facet.identifier,
                        label: quickFilter.label || facet.label,
                        options: facet.entries.map((entry) => entry.label),
                        values: filterValues
                    };
                    return filter;
                })
        );
    }, [facets]);

    const onFilterChange = (filter) => {
        api.removeFiltersByIdentifier(filter.id);
        filter.values.map((val) => api.addFilter({ identifier: filter.id, value: val }));
        api.search();
    };

    return <QuickFilterList filters={newFilters || []} onFilterChanged={onFilterChange} />;
};
