import { useEffect } from 'react';
import { usePersistedSearchkitVariables } from '../components/filtering/filters';

type DefaultFilter = {
    identifier: string;
    value: string | boolean;
};

type DefaultFilters = DefaultFilter[];

const useDefaultFilters = (id: string, defaultFilters: DefaultFilters) => {
    const { variables } = usePersistedSearchkitVariables(id);

    const removedFiltersKey = 'removedFilters';

    const getRemovedFilters = () => {
        const removedFilters = JSON.parse(localStorage.getItem(removedFiltersKey) || '{}');
        return removedFilters[id] || [];
    };

    const setRemovedFilters = (updatedFilters) => {
        const allRemovedFilters = JSON.parse(localStorage.getItem(removedFiltersKey) || '{}');
        allRemovedFilters[id] = updatedFilters;
        localStorage.setItem(removedFiltersKey, JSON.stringify(allRemovedFilters));
    };

    const initializeFilters = () => {
        const storedData = JSON.parse(localStorage.getItem(`filters_${id}`) || '{}');
        const storedFilters = storedData?.filters || [];
        const removedFilters = getRemovedFilters();

        const defaultFiltersToAdd = defaultFilters.filter(
            (defaultFilter) =>
                !removedFilters.some((removedFilter) => removedFilter.identifier === defaultFilter.identifier)
        );

        const mergedFilters = [
            ...defaultFiltersToAdd.filter(
                (defaultFilter) =>
                    !storedFilters.some((storedFilter) => storedFilter.identifier === defaultFilter.identifier)
            ),
            ...storedFilters
        ];

        const updatedData = {
            ...storedData,
            filters: mergedFilters
        };
        localStorage.setItem(`filters_${id}`, JSON.stringify(updatedData));
        variables.filters = mergedFilters;
    };

    const handleFilterChanges = () => {
        const currentFilters = variables?.filters || [];
        const removedFilters = getRemovedFilters();

        const removedDefaults = defaultFilters.filter(
            (defaultFilter) => !currentFilters.some((filter) => filter.identifier === defaultFilter.identifier)
        );

        const updatedRemovedFilters = [
            ...removedFilters.filter(
                (removedFilter) =>
                    !removedDefaults.some((defaultFilter) => defaultFilter.identifier === removedFilter.identifier)
            ),
            ...removedDefaults.map((filter) => ({ identifier: filter.identifier }))
        ];

        const finalRemovedFilters = updatedRemovedFilters.reduce((unique, filter) => {
            if (!unique.some((item) => item.identifier === filter.identifier)) {
                unique.push(filter);
            }
            return unique;
        }, []);

        setRemovedFilters(finalRemovedFilters);
    };

    useEffect(() => {
        initializeFilters();
    }, []);

    useEffect(() => {
        handleFilterChanges();
    }, [variables?.filters?.length]);
};

export default useDefaultFilters;
