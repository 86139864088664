import React, { useEffect, useState } from 'react';
import { EMPTY_PERSON, EMPTY_PERSON_FOR_ORG } from '../person';
import { cloneDeep, set } from 'lodash';
import { V } from '../../../Layout';
import { FormProvider } from '../../../form/FormContext';
import { getValidationRules } from './utilities';
import FieldInputs from './FieldInputs';
import { Person, PersonInput } from 'sr-types/lib/person/v1/graphql';
import { SxProps } from '@mui/material';

type PersonQuickAddProps = {
    newContactData: Map<string, string>;
    onCancel: Function;
    onAdd: (person: Person) => void;
    id?: string;
    showOnlyBasicInfoFields?: boolean;
    existingContactData?: PersonInput;
    showOnlyOrganizationField?: boolean;
    containerSx?: SxProps;
};

export default (props: PersonQuickAddProps) => {
    const {
        newContactData,
        onCancel,
        onAdd,
        id,
        existingContactData,
        containerSx,
        showOnlyBasicInfoFields,
        showOnlyOrganizationField
    } = props;

    const isQuickAddOrgFlow = id === 'org-quick-person-add';
    const validationRules = getValidationRules(isQuickAddOrgFlow);

    const [values, setValues] = useState<PersonInput>(
        cloneDeep(isQuickAddOrgFlow ? EMPTY_PERSON_FOR_ORG : EMPTY_PERSON)
    );

    useEffect(() => {
        if (newContactData.size) {
            set(values, 'name.firstName', newContactData.get('firstName') || '');
            set(values, 'name.lastName', newContactData.get('lastName') || '');
        }
    }, [newContactData]);

    useEffect(() => {
        if (existingContactData && !isQuickAddOrgFlow) {
            setValues(existingContactData);
        }
    }, [existingContactData]);

    return (
        <V
            sx={{
                borderRadius: 2,
                p: 2,
                ...containerSx
            }}
        >
            <FormProvider state={values} setState={setValues} validationRules={validationRules}>
                <FieldInputs
                    isQuickAddOrgFlow={isQuickAddOrgFlow}
                    onCancel={onCancel}
                    onAdd={onAdd}
                    showOnlyBasicInfoFields={showOnlyBasicInfoFields}
                    showOnlyOrganizationField={showOnlyOrganizationField}
                />
            </FormProvider>
        </V>
    );
};
