import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserContext } from '../common/auth/UserContext';
import {
    getCalendarAndEntity,
    planningResourceToBryntumResourcesAndCalendars,
    productToBryntumResourcesAndCalendars,
    toBryntumCalendars,
    toBryntumEventsAndAssignments,
    toBryntumEventsAndAssignmentsForActivity,
    toLineItemsFromProductionActivity,
    toSchedulerLineItemsFromReservations,
    toBryntumResourceProduction,
    toBryntumDependenciesForActivity
} from './schedulerUtil';
import { SUPPLIER, PLANNING } from '../common/nav/apps';
import {
    updateAndIntializeSchedulerData,
    getProductsData,
    initializeScheduler,
    getResourceData,
    getProductionData
} from './scheduler';
import { getFeaturePermissions } from '../common/auth/api';
import { isEmpty } from 'lodash';
import { constants } from '../common/constants';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import db from '../common/indexedDB';
import { toBryntumCalTime } from '../common/utils/dateTime';
import { DateHelper } from '@bryntum/schedulerpro';
import { useRecoilState } from 'recoil';
import { isSchedulerProductionRefetchNeededAtom } from '../production/schedulerTimeline/ProductionSchedulerRefetchAtom';

type SchedulerContextType = {
    schedulerData: any;
};

export const SchedulerContext = createContext<SchedulerContextType>(undefined);

export const getCalendars = (activeOrganizationAccount) => {
    return searchClient.query({
        query: slimResultsQuery('Calendar'),
        variables: {
            filters: [{ identifier: 'entity', value: 'Calendar' }],
            page: {
                from: 0,
                size: 200
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const toBryntumCalendarIntervals = (calendar) => {
    const intervals = [];
    calendar.workingTime.timeIntervals.forEach((currentInterval, index) => {
        if (currentInterval.startTime === currentInterval.endTime) {
            // entire day interval
            let nextDay = '';
            if (index == calendar.workingTime.timeIntervals.length - 1) {
                //last interval
                const nextDayIndex = daysOfTheWeek.findIndex((day) => day == currentInterval.day);
                nextDay = daysOfTheWeek[nextDayIndex + 1] || daysOfTheWeek[0];
            } else {
                nextDay = calendar.workingTime.timeIntervals[index + 1].day;
            }
            const bInterval = {
                recurrentStartDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.startTime)}`,
                recurrentEndDate: `on ${nextDay} at ${toBryntumCalTime(currentInterval.endTime)}`,
                isWorking: true
            };
            intervals.push(bInterval);
        } else {
            const bInterval = {
                recurrentStartDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.startTime)}`,
                recurrentEndDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.endTime)}`,
                isWorking: true
            };
            intervals.push(bInterval);
        }
    });
    return intervals;
};

export const SchedulerProvider = ({ children }) => {
    const { userProfile, activeOrganizationAccount, application, isAuthenticated, organizationAccountDetails } =
        useContext(UserContext);
    const [isSchedulerProductionRefetchNeeded, setIsSchedulerProductionRefetchNeeded] = useRecoilState(
        isSchedulerProductionRefetchNeededAtom
    );
    const [schedulerData, setSchedulerData] = useState(undefined);
    const shouldAutoSchedule = !isEmpty(
        getFeaturePermissions(userProfile, application, activeOrganizationAccount, 'Auto Schedule')
    );
    let instanceName = '_schedulerData';
    if (application?.name === PLANNING.name) {
        instanceName = '_schedulerProductionData';
    }

    useEffect(() => {
        if (isAuthenticated && activeOrganizationAccount) {
            db.table('schedulerStores')
                .where('name')
                .equals(activeOrganizationAccount + instanceName)
                .toArray()
                .then((data) => {
                    if (
                        !isEmpty(data) &&
                        DateHelper.as('hour', Date.now() - data[0].value.lastFetchedAt.getTime(), 'ms') < 1
                    ) {
                        setSchedulerData(data[0].value);
                        initializeScheduler(data[0].value, organizationAccountDetails);
                    } else {
                        // load data for reservations
                        if (application?.name === SUPPLIER.name) {
                            const { calendarsPromise, entityPromise } = getCalendarAndEntity(
                                'Reservation',
                                activeOrganizationAccount
                            );

                            const productPromise = getProductsData(activeOrganizationAccount).then(
                                (res) => res?.data?.results?.hits?.items
                            );
                            Promise.all([calendarsPromise, entityPromise, productPromise]).then((data) => {
                                const [calendarData, reservations, products] = data;
                                const bryntumCalendars = toBryntumCalendars(calendarData);
                                const schedulerLineItems = toSchedulerLineItemsFromReservations(reservations ?? []);
                                const { resources } = productToBryntumResourcesAndCalendars(products);
                                const { events, assignments } = toBryntumEventsAndAssignments(schedulerLineItems);
                                const storeData = {
                                    events: events,
                                    resources: resources,
                                    assignments: assignments,
                                    calendars: [...bryntumCalendars],
                                    lastFetchedAt: new Date()
                                };
                                updateAndIntializeSchedulerData(
                                    setSchedulerData,
                                    storeData,
                                    activeOrganizationAccount,
                                    'schedulerData',
                                    organizationAccountDetails
                                );
                            });
                        } else if (application?.name === PLANNING.name) {
                            setSchedulerData(undefined);
                            const { calendarsPromise, entityPromise } = getCalendarAndEntity(
                                'Activity',
                                activeOrganizationAccount
                            );

                            const resourcePromise = getResourceData(activeOrganizationAccount).then(
                                (res) => res?.data?.results?.hits?.items
                            );
                            const productionPromise = getProductionData(activeOrganizationAccount).then(
                                (res) => res?.data?.results?.hits?.items
                            );
                            Promise.all([calendarsPromise, entityPromise, resourcePromise, productionPromise]).then(
                                (data) => {
                                    const [calendarData, productionActivity, planningResources, productionData] = data;
                                    const lineItems = toLineItemsFromProductionActivity(productionActivity);
                                    const bryntumCalendars = toBryntumCalendars(calendarData);
                                    const { production } = toBryntumResourceProduction(productionData);
                                    const { resources } =
                                        planningResourceToBryntumResourcesAndCalendars(planningResources);
                                    const { events, assignments } = toBryntumEventsAndAssignmentsForActivity(
                                        lineItems,
                                        application
                                    );
                                    const { dependencies } = toBryntumDependenciesForActivity(lineItems);
                                    const storeData = {
                                        events: events,
                                        resources: [...resources, ...production],
                                        assignments: assignments,
                                        calendars: [...bryntumCalendars],
                                        dependencies: dependencies,
                                        lastFetchedAt: new Date()
                                    };
                                    updateAndIntializeSchedulerData(
                                        setSchedulerData,
                                        storeData,
                                        activeOrganizationAccount,
                                        'schedulerProductionData',
                                        organizationAccountDetails
                                    );
                                }
                            );
                        }
                    }
                });
        }
    }, [shouldAutoSchedule, isAuthenticated, activeOrganizationAccount, isSchedulerProductionRefetchNeeded]);

    return (
        <SchedulerContext.Provider
            value={{
                schedulerData
            }}
        >
            {children}
        </SchedulerContext.Provider>
    );
};
