import { createSvgIcon } from '@mui/material';
import React from 'react';

export const BusinessContact = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 6L3.99998 4.00002C3.99996 2.89544 4.8954 2 5.99998 2H18.0002C19.1048 2 20.0002 2.89543 20.0002 4V20C20.0002 21.1046 19.1048 22 18.0002 22H5.99995C4.89538 22 3.99995 21.1046 3.99995 20V18M4 6C4 6 6.0034 5.5 6.0017 7C6 8.5 4 8 4 8M4 6C4 6 2 5.5 2 7C2 8.5 4 8 4 8M3.99995 18C3.99995 18 6.00339 18.5 6.0017 16.9728C6.0001 15.5 4 16 4 16M3.99995 18C3.99995 18 2 18.5 2.0001 16.9728C2.0002 15.5 4 16 4 16M4 16L3.99995 13.1861L4 8"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="12" cy="9.5" r="2.5" fill="currentColor" fillOpacity="1" />
            <path
                d="M16.5 17C16.5 15.6739 16.0259 14.4021 15.182 13.4645C14.3381 12.5268 13.1935 12 12 12C10.8065 12 9.66193 12.5268 8.81802 13.4645C7.97411 14.4021 7.5 15.6739 7.5 17L16.5 17Z"
                fill="currentColor"
                fillOpacity="1"
            />
        </svg>
    </>,
    'BusinessContact'
);
