import React from 'react';
import { FormContainer } from '../../../common/form/FormContainer';
import FormItem from '../../../common/form/FormItem';
import FormWidget from '../../../common/form/FormWidget';
import I18n from '../../../common/i18n/I18n';
import { Production_Field_Names } from '../../helpers/productionUtils';

export const TrackSectionTitle = () => {
    return (
        <FormItem>
            <FormWidget
                component="SectionTitle"
                i18nToken="production.form.title.track"
                containerSx={{ textTransform: 'uppercase' }}
            />
        </FormItem>
    );
};

type CreateProductionTrackSectionType = {
    onCreateHandle: (e) => void;
    hidden?: boolean;
    disabledFields?: Record<string, boolean>;
};

export default ({ onCreateHandle, hidden = false, disabledFields = {} }: CreateProductionTrackSectionType) => {
    return (
        <>
            <FormItem hidden={hidden}>
                <TrackSectionTitle />
            </FormItem>
            <FormContainer>
                {/* We may use it later on */}
                {/* When we enable it for Template, we need to make sure to handle it for Stepper Template */}
                {/* <FormItem half>
                <FormWidget
                    component="ReferenceAutocomplete"
                    entity="Person"
                    name="projectOwner"
                    label={<I18n token="production.form.field.owner" />}
                    addOption
                    OptionRenderer={PersonOptionRenderer}
                    getOption={(item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            role: item.role,
                            label: item.name,
                            ref: { ...item.reference, label: item.name }
                        };
                    }}
                    onCreateHandle={onCreateHandle}
                    preload={false}
                />
            </FormItem> */}
                <FormItem half hidden={hidden}>
                    <FormWidget
                        component="MetadataAutocomplete"
                        metadataName="Termset"
                        filters={[{ identifier: 'name', value: 'ProductionProjectStatus' }]}
                        termset="ProductionProjectStatus"
                        name={Production_Field_Names.projectStatus}
                        label={<I18n token="production.form.field.status" />}
                        allowCreate
                        disabled={disabledFields[Production_Field_Names.projectStatus]}
                    />
                </FormItem>
            </FormContainer>
        </>
    );
};
