import { createSvgIcon } from '@mui/material';
import React from 'react';

export const AutoAwesomeGradient = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 9L20.25 6.25L23 5L20.25 3.75L19 1L17.75 3.75L15 5L17.75 6.25L19 9Z"
                fill="url(#paint0_angular_4530_37624)"
            />
            <path
                d="M19 15L17.75 17.75L15 19L17.75 20.25L19 23L20.25 20.25L23 19L20.25 17.75L19 15Z"
                fill="url(#paint1_angular_4530_37624)"
            />
            <path
                d="M11.5 9.5L9 4L6.5 9.5L1 12L6.5 14.5L9 20L11.5 14.5L17 12L11.5 9.5ZM9.99 12.99L9 15.17L8.01 12.99L5.83 12L8.01 11.01L9 8.83L9.99 11.01L12.17 12L9.99 12.99Z"
                fill="url(#paint2_angular_4530_37624)"
            />
            <defs>
                <radialGradient
                    id="paint0_angular_4530_37624"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.9679 12.0705) rotate(-1.45045) scale(16.5637 15.753)"
                >
                    <stop offset="0.249916" stop-color="#0011A8" />
                    <stop offset="0.707576" stop-color="#435FFE" />
                </radialGradient>
                <radialGradient
                    id="paint1_angular_4530_37624"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.9679 12.0705) rotate(-1.45045) scale(16.5637 15.753)"
                >
                    <stop offset="0.249916" stop-color="#0011A8" />
                    <stop offset="0.707576" stop-color="#435FFE" />
                </radialGradient>
                <radialGradient
                    id="paint2_angular_4530_37624"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.9679 12.0705) rotate(-1.45045) scale(16.5637 15.753)"
                >
                    <stop offset="0.249916" stop-color="#0011A8" />
                    <stop offset="0.707576" stop-color="#435FFE" />
                </radialGradient>
            </defs>
        </svg>
    </>,
    'AutoAwesomeGradient'
);
