import React from 'react';
import { autoCreateQuery, client } from '../../../../organization/organization';
import OrganizationOptionRenderer from '../../../../organization/OrganizationOptionRenderer';
import { getOption, quickAddOrganization } from '../../../../organization/QuickAddOrganization';
import FormItem from '../../../form/FormItem';
import FormWidget from '../../../form/FormWidget';
import { useMutationWithContext } from '../../../hooks/useMutationWithContext';
import I18n from '../../../i18n/I18n';

export default ({
    isQuickAddOrgFlow,
    showOnlyOrganizationField
}: {
    isQuickAddOrgFlow: boolean;
    showOnlyOrganizationField?: boolean;
}) => {
    const [saveOrganization] = useMutationWithContext(autoCreateQuery, client);

    return (
        <>
            {(!isQuickAddOrgFlow || showOnlyOrganizationField) && (
                <>
                    <FormItem half>
                        <FormWidget
                            name={'experiences.organizationReference'}
                            label={<I18n token={'person.experiences.organization'} />}
                            component="ReferenceAutocomplete"
                            entity="Organization"
                            OptionRenderer={OrganizationOptionRenderer}
                            onCreateHandle={quickAddOrganization}
                            getOption={getOption}
                            saveMutation={saveOrganization}
                            addOption
                            preload={false}
                            filter={{
                                expressions: [{ field: 'inactive', value: { values: ['false'] } }]
                            }}
                        />
                    </FormItem>
                    {!showOnlyOrganizationField && (
                        <>
                            <FormItem half>
                                <FormWidget
                                    name={'experiences.title'}
                                    label={<I18n token={'person.experiences.title'} />}
                                    allowCreate
                                    component="MetadataAutocomplete"
                                    metadataName="Title"
                                />
                            </FormItem>
                            <FormItem half>
                                <FormWidget
                                    name={'experiences.department'}
                                    label={<I18n token={'person.experiences.department'} />}
                                    allowCreate
                                    component="MetadataAutocomplete"
                                    metadataName="Department"
                                />
                            </FormItem>
                        </>
                    )}
                </>
            )}
            {!showOnlyOrganizationField && (
                <FormItem half>
                    <FormWidget
                        name={'experiences.professionalRoles'}
                        label={<I18n token={'person.add.role'} />}
                        allowCreate
                        component="MetadataAutocomplete"
                        metadataName="Role"
                        multiple
                    />
                </FormItem>
            )}
        </>
    );
};
