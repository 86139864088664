import { createSvgIcon } from '@mui/material';
import React from 'react';

export const PrimaryContactEmail = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 3C21.1 3 22 3.9 22 5V12H20V7L12 12L4 7V17H12V19H4C2.9 19 2 18.1 2 17V5C2 3.9 2.9 3 4 3H20ZM12 10L20 5H4L12 10Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M14 15.5L14 15C14 13.8954 14.8954 13 16 13H20.0001C21.1047 13 22.0001 13.8954 22.0001 15V21C22.0001 22.1046 21.1047 23 20.0001 23H16C14.8954 23 14 22.1046 14 21L14 20.4864M14 15.5L14 18.5931L14 20.4864M14 15.5C14 15.5 15 15.25 15.0009 15.5C15.0017 15.75 14 15.5 14 15.5ZM14 15.5C14 15.5 13 15.75 13 15.5C13 15.25 14 15.5 14 15.5ZM14 20.4864C14 20.4864 15.0009 21 15 20.4864C14.9992 20 14 20.4864 14 20.4864ZM14 20.4864C14 20.4864 13.0001 20 13.0001 20.4864C13 21 14 20.4864 14 20.4864Z"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.5 16.75C19.5 17.4404 18.9404 18 18.25 18C17.5596 18 17 17.4404 17 16.75C17 16.0596 17.5596 15.5 18.25 15.5C18.9404 15.5 19.5 16.0596 19.5 16.75Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M20.5 20.5C20.5 19.837 20.2629 19.2011 19.841 18.7322C19.419 18.2634 18.8467 18 18.25 18C17.6533 18 17.081 18.2634 16.659 18.7322C16.2371 19.2011 16 19.837 16 20.5H20.5Z"
                fill="currentColor"
                fillOpacity="1"
            />
        </svg>
    </>,
    'PrimaryContactEmail'
);
