import { isEmpty, unionBy } from 'lodash';
import { constants } from '../constants';
import { searchClient, slimResultsQuery } from '../list/slimQuery';
import { log } from './commonUtils';

export const searchData = async (
    entity: string,
    activeOrganizationAccount: string,
    from: number,
    batchSize: number,
    filter: Record<any, any>,
    sortOrder: string,
    includeAllActions: boolean,
    additionalFragmentName: string
) => {
    try {
        let filters = [{ identifier: 'entity', value: entity }];
        !isEmpty(filter) && filters.push(filter);
        const initialResponse = await searchClient.query({
            query: slimResultsQuery(entity, includeAllActions, additionalFragmentName),
            variables: {
                filters: filters,
                page: {
                    from: from,
                    size: batchSize
                },
                sortBy: sortOrder
            },
            fetchPolicy: constants.apolloFetchPolicy,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        });
        return initialResponse;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const searchByPage = async (
    entity: string,
    activeOrganizationAccount: string,
    batchSize: number,
    filter: Record<any, any>,
    sortOrder: string,
    setBatchState: React.Dispatch<React.SetStateAction<any>>,
    setSearchData: React.Dispatch<React.SetStateAction<any>>,
    chunkSize: number = 2, // Controls how many requests are made at once
    includeAllActions = false,
    additionalFragmentName: string = undefined
) => {
    let totalFetched = 0;
    const searchedData = [];
    const start = Date.now();
    // First fetch to determine total records and get the first batch
    const res = await searchData(
        entity,
        activeOrganizationAccount,
        totalFetched,
        batchSize,
        filter,
        sortOrder,
        includeAllActions,
        additionalFragmentName
    );
    const firstBatchResults = res?.data?.results?.hits?.items;
    const totalRecords = res?.data?.results?.hits?.page?.total;

    // Set the first batch of results
    if (firstBatchResults) {
        setSearchData((prev) => unionBy([...prev, ...firstBatchResults], 'id'));
        setBatchState(firstBatchResults);
        searchedData.push(...firstBatchResults);
        totalFetched += firstBatchResults.length;
    }

    // Calculate how many more batches are needed
    const totalPages = Math.ceil(totalRecords / batchSize);

    // Process subsequent batches in chunks
    for (let currentPage = 1; currentPage < totalPages; currentPage += chunkSize) {
        const batchRequests = [];

        // create only `chunkSize` requests
        for (let i = 0; i < chunkSize && currentPage + i < totalPages; i++) {
            const pageStartIndex = (currentPage + i) * batchSize;
            batchRequests.push(
                searchData(
                    entity,
                    activeOrganizationAccount,
                    pageStartIndex,
                    batchSize,
                    filter,
                    sortOrder,
                    includeAllActions,
                    additionalFragmentName
                )
            );
        }

        // Wait for all requests in this chunk to resolve
        const results = await Promise.allSettled(batchRequests);

        // Process the results from the current chunk
        results.forEach((result) => {
            if (result.status === 'fulfilled') {
                const batchResults = result.value?.data?.results?.hits?.items;
                if (batchResults && !isEmpty(batchResults)) {
                    searchedData.push(...batchResults);
                    totalFetched += batchResults.length;
                }
            } else {
                console.error('Batch fetch failed:', result.reason);
            }
        });
    }

    setBatchState(unionBy(searchedData, 'id'));
    const end = Date.now();
    log(`** All done, Execution time: ${end - start} ms`);
    return searchedData;
};
