import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, Chip, Collapse, TextField, Tooltip, Typography } from '@mui/material';
import { optionToContact } from './person/person';
import { UserContext } from '../auth/UserContext';
import { debounce, get } from 'lodash';
import PersonQuickAdd from './person/quickAddPerson/PersonQuickAdd';
import { Contact } from './types';
import { FormContext } from '../form/FormContext';
import { constants } from '../constants';
import ContactOptionRenderer from '../../person/ContactOptionRenderer';
import { Conditional, V } from '../Layout';
import { searchClient, slimResultsQuery } from '../list/slimQuery';
import { PersonInput, Person } from 'sr-types/lib/person/v1/graphql';

type PersonSelectorProps = {
    id?: string;
    name: string;
    label?: string | ReactElement;
    multiple: boolean;
    includePublic: boolean;
    placeholder: string;
    noOptionsText: string;
    autoFocus?: boolean;
    value?: Array<Contact>;
    onChange?: (name, contacts: Contact[]) => void;
    setIsScrollable?: (scrollable: boolean) => void;
    size?: 'small' | 'medium';
    variant?: 'standard' | 'outlined' | 'filled';
    required?: boolean;
    disabled?: boolean;
    editContact?: boolean;
    filterOptions?: string[];
};

export default (props: PersonSelectorProps) => {
    const {
        id,
        label,
        value,
        onChange,
        required,
        disabled,
        multiple,
        variant = constants.defaultWidgetVariant,
        size = constants.defaultWidgetSize,
        name,
        autoFocus,
        noOptionsText,
        setIsScrollable,
        placeholder,
        includePublic,
        editContact = false,
        filterOptions = []
    } = props;
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [formData, setFormData] = useState(new Map<string, string>([]));
    const [contact, setContact] = useState<PersonInput>(undefined);
    const addFormRef = useRef(null);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const { validation, state, handleChange: commit } = useContext(FormContext);
    const usingFormContext = typeof onChange === 'undefined';
    const actualValues = React.useMemo(() => {
        return (usingFormContext ? get(state, name) : value) || [];
    }, [usingFormContext, state, name, value]);
    const doChange = usingFormContext ? commit : onChange;

    const resolvedErrors = usingFormContext && validation ? get(validation.errors, name) : [];
    const isRequired = usingFormContext && validation ? !!get(validation.required, name) : required;
    const errorText = (Array.isArray(resolvedErrors) ? resolvedErrors.join(', ') : resolvedErrors) || '';
    const isError = errorText.length > 0;

    let textInputRef = React.useRef();

    useEffect(() => {
        if (textInputRef && textInputRef.current && autoFocus) {
            // @ts-ignore
            textInputRef?.current?.focus();
        }
    }, [textInputRef]);

    const getOptionLabel = (option) => `${option.name?.firstName ?? ''} ${option.name?.lastName ?? ''}`;

    useEffect(() => {
        if (actualValues && actualValues.length && !multiple) {
            setSearchText(getOptionLabel(actualValues[0]));
        }
    }, [actualValues]);

    const updateOptions = useCallback(
        (term) => {
            if (term) {
                setLoading(true);
                const filters = [{ identifier: 'entity', value: 'Person' }];
                if (!includePublic) {
                    filters.push({ identifier: 'public', value: 'false' });
                }
                searchClient
                    .query({
                        query: slimResultsQuery('Person'),
                        variables: {
                            query: term,
                            page: { from: 0, size: 10 },
                            filters: filters,
                            tokenize: true
                        },
                        context: {
                            headers: {
                                ownerId: activeOrganizationAccount
                            }
                        },
                        fetchPolicy: constants.apolloFetchPolicy
                    })
                    .then((result) => {
                        const opts = result.data?.results.hits.items.map((person) => {
                            const names = person.name ? person.name.split(' ') : [];
                            return {
                                identity: { id: person.id },
                                name: {
                                    firstName: names[0],
                                    lastName: names.length > 1 ? names.slice(1).join(' ') : ''
                                },
                                contactInfo: person.contactInfo,
                                personReference: person.reference,
                                organization: person.organization,
                                organizationReference: person.organizationReference,
                                productionReference: person.productionReference,
                                productionDepartment: person.productionDepartment,
                                role: person.role,
                                title: person.title,
                                department: person.department,
                                production: person.production,
                                productionRole: person.productionRole,
                                createOption: false
                            };
                        });
                        opts.push({
                            name: `Add ${term}`,
                            contactInfo: undefined,
                            createOption: true
                        });
                        setOptions([...opts, ...actualValues]);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                setOptions(actualValues);
            }
        },
        [activeOrganizationAccount, actualValues, includePublic]
    );

    useEffect(() => {
        if (actualValues && actualValues.length) {
            setOptions(actualValues);
        }
    }, [actualValues]);

    const debouncedUpdateOptions = useMemo(() => debounce(updateOptions, 150), [updateOptions]);

    useEffect(() => {
        debouncedUpdateOptions(searchText);
    }, [debouncedUpdateOptions, searchText]);

    useEffect(() => {
        if (showAddForm) {
            setTimeout(() => {
                addFormRef.current.scrollIntoViewIfNeeded({ behavior: 'smooth' });
            }, 500);
        }
    }, [showAddForm]);

    const handleAdd = (person: Person) => {
        if (person) {
            const newContact = {
                name: person.name,
                contactInfo: person.contactInfo,
                personReference: person.reference,
                organization: '',
                role: '',
                production: '',
                productionRole: '',
                isPrimary: false
            };
            const latestExperience =
                person.experiences && person.experiences.length > 0
                    ? person.experiences[person.experiences.length - 1]
                    : undefined;
            if (latestExperience) {
                newContact.organization = latestExperience.experienceName;
                if (latestExperience.professionalRoles?.length > 0) {
                    newContact.role = latestExperience.professionalRoles[0];
                }
            }
            const latestCredit =
                person.creativeCredits && person.creativeCredits.length > 0
                    ? person.creativeCredits[person.creativeCredits.length - 1]
                    : undefined;
            if (latestCredit) {
                newContact.production = latestCredit.showName;
                if (latestCredit.professionalRoles?.length > 0) {
                    newContact.productionRole = latestCredit.professionalRoles[0];
                }
            }
            if (multiple) {
                doChange(name, [...actualValues, newContact]);
            } else {
                doChange(name, [newContact]);
            }
        }
        setShowAddForm(false);
        if (typeof setIsScrollable === 'function') {
            setIsScrollable(false);
        }
    };

    const handleCancel = () => {
        setShowAddForm(false);
        setContact(undefined);
        if (typeof setIsScrollable === 'function') {
            setIsScrollable(false);
        }
    };

    const handleChange = (selectedOptions) => {
        const contacts = selectedOptions.map((opt) => {
            return {
                name: opt.name,
                contactInfo: opt.contactInfo,
                organization: opt.organization,
                role: opt.role,
                production: opt.production,
                productionRole: opt.productionRole,
                personReference: opt.personReference
            };
        });
        if (multiple) {
            doChange(name, contacts);
        } else {
            const c = contacts.length ? [contacts[contacts.length - 1]] : [];
            doChange(name, c);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Autocomplete
                loading={loading}
                disablePortal
                id="person-selector"
                fullWidth
                multiple={multiple}
                filterOptions={(options) => {
                    if (filterOptions.length) {
                        return options.filter((option) => !filterOptions.includes(option?.personReference?.id));
                    }

                    return options;
                }}
                disabled={disabled}
                options={options}
                value={actualValues}
                freeSolo={false}
                isOptionEqualToValue={(option, value) => option?.personReference?.id === value?.personReference?.id}
                filterSelectedOptions
                autoHighlight={false}
                onChange={(event, newValue) => {
                    const arr = Array.isArray(newValue) ? newValue : newValue ? [newValue] : [];
                    const createSelected = arr.filter((option) => option.createOption);
                    if (createSelected.length) {
                        const names = searchText?.split(' ');
                        if (names && names.length) {
                            const newFormData = new Map<string, string>([
                                ['firstName', names[0]],
                                ['lastName', names.length > 1 ? names[1] : '']
                            ]);
                            setFormData(newFormData);
                        }
                        setShowAddForm(true);
                        if (typeof setIsScrollable === 'function') {
                            setIsScrollable(true);
                        }
                    } else {
                        const contactWithNoEmail = arr.find(
                            (contact) => !contact?.contactInfo || !contact?.contactInfo?.email?.address
                        );
                        const contact = contactWithNoEmail && optionToContact(contactWithNoEmail);
                        if (editContact && contact) {
                            setShowAddForm(true);
                            setContact(contact);
                            if (typeof setIsScrollable === 'function') {
                                setIsScrollable(true);
                            }
                        } else {
                            const selectedOptions = arr.filter((option) => !option.createOption);
                            handleChange(selectedOptions);
                        }
                    }
                }}
                limitTags={2}
                getOptionLabel={getOptionLabel}
                renderTags={(tagValue: Contact[], getTagProps) =>
                    tagValue.map((contact: Contact, index) => {
                        const name = `${contact.name?.firstName ?? ''} ${contact.name?.lastName ?? ''}`;
                        return (
                            <Tooltip
                                key={`${index}-tt`}
                                title={<ContactOptionRenderer option={contact} />}
                                componentsProps={{
                                    popper: {
                                        disablePortal: false
                                    }
                                }}
                                arrow
                            >
                                <Chip size={'small'} label={name} {...getTagProps({ index })} />
                            </Tooltip>
                        );
                    })
                }
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            // fullWidth
                            disabled={disabled}
                            required={isRequired}
                            variant={variant}
                            label={id ? label : ''}
                            error={isError}
                            helperText={errorText || ' '} // Without this PersonSelector has smaller space bellow then other components.
                            placeholder={placeholder}
                            ref={textInputRef}
                            focused={autoFocus}
                            autoFocus={autoFocus}
                            InputProps={{
                                ...params.InputProps,
                                // required: required,
                                size: size
                                // sx: { pr: variant !== 'outlined' ? '0px !important' : undefined }, // TODO Hack
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         <SearchOutlined />
                                //     </InputAdornment>
                                // )
                            }}
                        />
                    );
                }}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" {...props} key={props.id}>
                            {option.createOption ? (
                                <Box key={option.name} sx={{ width: '100%' }}>
                                    <Typography sx={{ fontWeight: '300' }}>{option.name}</Typography>
                                </Box>
                            ) : (
                                <ContactOptionRenderer option={option} sx={{ width: '100%' }} />
                            )}
                        </Box>
                    );
                }}
                inputValue={searchText}
                onInputChange={(event, value) => {
                    if (event) {
                        setSearchText(value);
                    }
                }}
                noOptionsText={noOptionsText}
            />
            <Collapse ref={addFormRef} in={showAddForm} timeout={500}>
                <Conditional condition={showAddForm}>
                    {() => (
                        <PersonQuickAdd
                            id={id}
                            newContactData={formData}
                            existingContactData={contact}
                            onCancel={handleCancel}
                            onAdd={handleAdd}
                            containerSx={{
                                boxShadow:
                                    'rgb(0 0 0 / 5%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 5%) 0px 3px 14px 2px',
                                mb: 6
                            }}
                        />
                    )}
                </Conditional>
            </Collapse>
        </Box>
    );
};
