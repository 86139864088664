import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { Person } from 'sr-types/lib/person/v1/graphql';
import FormButtons from '../../../form/FormButtons';
import { FormContainer } from '../../../form/FormContainer';
import { FormContext } from '../../../form/FormContext';
import { V } from '../../../Layout';
import { stateToPerson } from '../person';
import BasicQuickAddPersonFields from './BasicQuickAddPersonFields';
import CreativeCreditsFields from './CreativeCreditsFields';
import ExperiencesFields from './ExperiencesFields';
import { useAutoCreatePerson } from './utilities';

export default ({
    isQuickAddOrgFlow,
    onCancel,
    onAdd,
    showOnlyBasicInfoFields = false,
    showOnlyOrganizationField = false
}: {
    isQuickAddOrgFlow: boolean;
    onCancel: Function;
    onAdd: (person: Person) => void;
    showOnlyBasicInfoFields?: boolean;
    showOnlyOrganizationField?: boolean;
}) => {
    const { autoCreatePerson, isAutoCreating } = useAutoCreatePerson();
    const { state } = useContext(FormContext);

    const [saveDisabled, setIsSaveDisabled] = useState<boolean>(false);

    const savePerson = () => {
        const savedEntity = stateToPerson(state);

        autoCreatePerson(savedEntity).then((id: string) => {
            const person = {
                ...(savedEntity as Person),
                reference: { id, label: `${savedEntity.name?.firstName || ''} ${savedEntity.name.lastName || ''}` }
            };
            onAdd(person);
        });
    };

    return (
        <V fill sx={{ justifyContent: 'space-between' }}>
            <FormContainer>
                <BasicQuickAddPersonFields setIsSaveDisabled={setIsSaveDisabled} />
                <ExperiencesFields
                    isQuickAddOrgFlow={isQuickAddOrgFlow}
                    showOnlyOrganizationField={showOnlyOrganizationField}
                />
                {!showOnlyBasicInfoFields && <CreativeCreditsFields />}
            </FormContainer>
            <FormButtons
                onClickCancel={() => onCancel?.()}
                onClickSave={savePerson}
                isSaving={isAutoCreating}
                disableSave={saveDisabled}
            />
        </V>
    );
};
