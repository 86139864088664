import React, { useEffect } from 'react';
import { useSearchkit } from '@searchkit/client';
import { Typography } from '@mui/material';
import { formatISO, parseISO } from 'date-fns';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { toDateTimeInUTC, adjustDateRangeToSuppressLocalTZ } from '../../../common/utils/dateTime';
import { V } from '../../../common/Layout';

export const DateRangeFacet = ({ facet, loading, isLocal }) => {
    const api = useSearchkit();
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

    const getSelectedOption = () => {
        const selectedOptions = api.getFiltersByIdentifier(facet.identifier);
        return selectedOptions && selectedOptions[0];
    }; 

    const onChange = (value) => {
        setValue(value);
        if (value[0] != null && value[1] != null) {
            const selectedOption = getSelectedOption();
            if (selectedOption) {
                api.removeFilter(selectedOption);
            }
            let dateMin: string;
            let dateMax: string;
            if (isLocal) {
                // getting the date in Local TimeZone
                dateMin = formatISO(value[0]);
                dateMax = formatISO(value[1]);
            } else {
                // getting the date in UTC TimeZone
                dateMin = toDateTimeInUTC(value[0]);
                dateMax = toDateTimeInUTC(value[1]);
            }
            const pageSize = api.getSearchState().page.size;
            api.addFilter({
                identifier: facet.identifier,
                dateMin,
                dateMax
            });
            api.setPage({
                from: 0,
                size: pageSize
            });
            api.search();
        }
    };

    useEffect(() => {
        const selectedOption = getSelectedOption();
        if (selectedOption) {
            setValue(adjustDateRangeToSuppressLocalTZ([parseISO(selectedOption.dateMin), parseISO(selectedOption.dateMax)]));
        }
    }, []);

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{facet.label}</Typography>
            <DateRangePicker
                value={value}
                onAccept={onChange}
                slotProps={{
                    textField: {
                        size: 'small',
                        variant: 'standard',
                        fullWidth: true
                    }
                }}
            />
        </V>
    );
};

DateRangeFacet.DISPLAY = 'DateRangeFacet';
