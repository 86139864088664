import { useLazyQuery } from '@apollo/client';
import { useContext } from 'react';
import { PersonInput } from 'sr-types/lib/person/v1/graphql';
import { UserContext } from '../../../auth/UserContext';
import { constants } from '../../../constants';
import Validator from '../../../form/Validator';
import { useMutationWithContext } from '../../../hooks/useMutationWithContext';
import { searchClient, slimResultsQuery } from '../../../list/slimQuery';
import { failedToastMessage, successToastMessage } from '../../../utils/commonUtils';
import { autoCreateQuery, client } from '../person';

export const getValidationRules = (isQuickAddOrgFlow: boolean) => {
    let validationRules = {
        'name.firstName': [Validator.RULES.isRequired],
        'name.lastName': [Validator.RULES.isRequired],
        'contactInfo.email.address': [Validator.RULES.isRequired, Validator.RULES.email]
    };

    if (isQuickAddOrgFlow) {
        validationRules['contactInfo.phone.number'] = [Validator.RULES.phoneNumber];
    }
    return validationRules;
};

export const useGetUserByEmail = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [findUserDetails, { data: personData }] = useLazyQuery(slimResultsQuery('Person'), {
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    return { findUserDetails, personData };
};

export const useAutoCreatePerson = () => {
    const [save, { loading: isAutoCreating }] = useMutationWithContext(autoCreateQuery, client);

    const autoCreatePerson = (input: PersonInput) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    input
                }
            })
                .then((res) => {
                    handleAutoCreatePersonResponse({
                        res,
                        name: `${input.name.firstName} ${input.name.lastName}`,
                        resolve,
                        reject
                    });
                })
                .catch(() => {
                    failedToastMessage('form.save.failure', {
                        name: `${input.name.firstName} ${input.name.lastName}`
                    });
                });
        });
    };

    return { autoCreatePerson, isAutoCreating };
};

const handleAutoCreatePersonResponse = ({ res, name, resolve, reject }) => {
    if (res.data.autoCreatePerson.errors) {
        reject();
        failedToastMessage('form.save.failure', {
            name
        });
    } else {
        resolve(res.data.autoCreatePerson.id);
        successToastMessage('form.save.success', {
            name
        });
    }
};
