import React from 'react';
import { autoCreateQuery, client } from '../../../../production/helpers/production';
import { getOption, quickAddProduction } from '../../../../production/helpers/QuickAddProduction';
import { ProductionOptionRenderer } from '../../../../supply/booking/ProductionOptionRenderer';
import FormItem from '../../../form/FormItem';
import FormWidget from '../../../form/FormWidget';
import { useMutationWithContext } from '../../../hooks/useMutationWithContext';
import I18n from '../../../i18n/I18n';

export default () => {
    const [saveProduction] = useMutationWithContext(autoCreateQuery, client);

    return (
        <>
            <FormItem>
                <FormWidget
                    name={'creativeCredits.showReference'}
                    label={<I18n token={'person.creative.credits.production'} />}
                    addOption
                    component="ReferenceAutocomplete"
                    entity="Production"
                    OptionRenderer={ProductionOptionRenderer}
                    onCreateHandle={quickAddProduction}
                    getOption={getOption}
                    saveMutation={saveProduction}
                />
            </FormItem>
            <FormItem half>
                <FormWidget
                    name={'creativeCredits.department'}
                    label={<I18n token={'person.creative.credits.production.department'} />}
                    allowCreate
                    component="MetadataAutocomplete"
                    metadataName="Department"
                />
            </FormItem>
            <FormItem half>
                <FormWidget
                    name={'creativeCredits.professionalRoles'}
                    label={<I18n token={'person.add.role'} />}
                    allowCreate
                    component="MetadataAutocomplete"
                    metadataName="Role"
                    multiple
                />
            </FormItem>
        </>
    );
};
