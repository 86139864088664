import { Alert, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormContext } from '../../../form/FormContext';
import FormItem from '../../../form/FormItem';
import FormWidget from '../../../form/FormWidget';
import I18n, { useI18n } from '../../../i18n/I18n';
import { V } from '../../../Layout';
import { getReferenceId } from '../../../reference/reference';
import { useGetUserByEmail } from './utilities';

export default ({ setIsSaveDisabled }: { setIsSaveDisabled: Dispatch<SetStateAction<boolean>> }) => {
    const { state } = useContext(FormContext);
    const { findUserDetails, personData } = useGetUserByEmail();

    const [personExists, setPersonExists] = useState<boolean>(false);

    useEffect(() => {
        const id = personData?.results?.hits?.items[0] && getReferenceId(personData.results.hits.items[0]);
        const exists = id !== state?.reference?.id;
        setPersonExists(exists);
        setIsSaveDisabled(exists);
    }, [personData]);

    const onBlur = () => {
        findUserDetails({
            variables: {
                filters: [
                    { identifier: 'entity', value: 'Person' },
                    { identifier: 'email', value: state?.contactInfo?.email?.address }
                ]
            }
        });
    };

    return (
        <>
            <FormItem>
                <FormWidget name="name.firstName" label={useI18n('person.add.first.name')} />
            </FormItem>
            <FormItem>
                <FormWidget name="name.lastName" label={useI18n('person.add.last.name')} />
            </FormItem>
            <FormItem>
                <FormWidget name="contactInfo.email.address" label={useI18n('person.add.email')} onBlur={onBlur} />
            </FormItem>
            {personExists && (
                <FormItem>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        <V>
                            <Typography>
                                <I18n token="person.add.email.exist.message" />
                            </Typography>
                        </V>
                    </Alert>
                </FormItem>
            )}
            <FormItem>
                <FormWidget
                    component="PhoneNumber"
                    name="contactInfo.phone.number"
                    label={useI18n('person.add.phone')}
                />
            </FormItem>
        </>
    );
};
