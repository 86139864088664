import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ResumeIcon = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 2C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H18Z"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M15 7L17 7" stroke="currentColor" strokeOpacity="1" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M15 10H17" stroke="currentColor" strokeOpacity="1" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M7 14H17" stroke="currentColor" strokeOpacity="1" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M7 18H17" stroke="currentColor" strokeOpacity="1" strokeWidth="1.5" strokeLinecap="round" />
            <circle
                cx="9.5"
                cy="5.5"
                r="1.5"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M12 10C12 9.73736 11.9353 9.47728 11.8097 9.23463C11.6841 8.99198 11.4999 8.7715 11.2678 8.58579C11.0356 8.40007 10.76 8.25275 10.4567 8.15224C10.1534 8.05173 9.8283 8 9.5 8C9.1717 8 8.84661 8.05173 8.54329 8.15224C8.23998 8.25275 7.96438 8.40007 7.73223 8.58579C7.50009 8.7715 7.31594 8.99198 7.1903 9.23463C7.06466 9.47728 7 9.73736 7 10"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    </>,
    'ResumeIcon'
);
