import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useMutation } from '@apollo/client';
import { cloneDeep, isEmpty, set } from 'lodash';
import { authLink } from '../../common/auth/api';
import { Location, LOCATION_FIELDS } from '../../common/components/location';
import { ReferenceField } from '../../common/reference/reference';
import { Contact, MoneyInput } from 'sr-types/lib/production/v1/graphql';
import { Icons } from '../../common/icons/Icons';
import { SCHEDULE_FIELDS } from '../../admin/templates/resources/resources';
import { DateRangeFragment, ReferenceFragment } from '../../common/list/fragments';
import {
    EMPTY_ABOUT_INFO,
    EMPTY_ALT_IDS,
    EMPTY_ASSOCIATED_ORGS,
    EMPTY_ASSOCIATED_PPL,
    EMPTY_PROD_INFO,
    EMPTY_PROJECT_TRACK
} from '../form/ProductionEditor';
import { EMPTY_PRODUCTION_DATES } from './productionUtils';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/production/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const bulkSaveQuery: DocumentNode = gql`
    mutation BulkSaveProduction($input: BulkSaveProductionRequest!) {
        bulkSaveProduction(input: $input) {
            createdCount
            updatedCount
            unprocessedCount
            results {
                id
                errors {
                    field
                    message
                }
                reference {
                    id
                }
            }
            logFile {
                id
                uriPrefix
            }
        }
    }
`;

export const saveQuery: DocumentNode = gql`
    mutation SaveProduction($input: ProductionInput!) {
        saveProduction(input: $input) {
            id
            errors {
                field
                message
            }
            reference {
                id
                type
                label
                deleted
                eventId
            }
        }
    }
`;

export const preSaveQuery: DocumentNode = gql`
    mutation PreSaveProduction {
        preSaveProduction {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const detailsQuery: DocumentNode = gql`
    query Production($id: ID!) {
        production(id: $id) {
            identity {
                id
            }
            about
            budget {
                currencyCode
                nanos
                units
            }
            name
            otherTitles {
                key
                value
            }
            projectOwner {
                ...Reference
            }
            projectStatus
            productionType
            productionStatus
            genre
            releaseDate
            releaseYear
            productionYears
            storageAssets {
                referenceName
                provider
                storageUrl {
                    path
                    tldomain
                    subdomain
                }
            }
            showAttributes {
                key
                value
            }
            productionGroup {
                label
                productionReference {
                    id
                    eventId
                    uriPrefix
                    type
                    label
                    deleted
                }
            }
            associatedPeople {
                id
                name {
                    firstName
                    lastName
                    salutation
                    suffix
                }
                isPrimary
                contactInfo {
                    email {
                        address
                        typeString
                        verified
                    }
                    phone {
                        number
                        typeString
                    }
                }
                personReference {
                    ...Reference
                }
                role
                organization
                organizationReference {
                    ...Reference
                }
                label
                production
                productionRole
                productionReference {
                    ...Reference
                }
                title
                organizationDepartment
                productionDepartment
            }
            alternateIds {
                label
                value
                url
            }
            locations {
                ...LocationFields
            }
            associatedOrgs {
                name
                organizationReference {
                    ...Reference
                }
                organizationTypes
                organizationCategories {
                    value
                    lineage {
                        value
                    }
                }
            }
            managedProduction
            productionDates {
                autoSetDate
                dateRange {
                    ...DateRange
                }
            }
            keyActivities {
                name
                activityType
                durationSpecified
                dateRange {
                    ...DateRange
                }
                locations {
                    label
                    primary
                }
            }
            plans {
                name
                uri
                description
                createdOn
                updatedOn
                identity {
                    id
                    preSave
                }
                keyActivities {
                    id
                    eventId
                    uriPrefix
                    type
                    label
                    deleted
                }
                activities(page: { size: 1000, from: 0 }) {
                    identity {
                        id
                    }
                    name
                    uri
                    activityType
                    durationSpecified
                    createdOn
                    updatedOn
                    category {
                        value
                        lineage {
                            value
                        }
                    }
                    dateRange {
                        ...DateRange
                    }
                }
            }
            keyPeople {
                id
                name {
                    firstName
                    lastName
                    salutation
                    suffix
                }
                isPrimary
                contactInfo {
                    email {
                        address
                        typeString
                        verified
                    }
                    phone {
                        number
                        typeString
                    }
                }
                personReference {
                    id
                }
                role
                organization
                organizationReference {
                    id
                }
                label
                production
                productionRole
                title
                organizationDepartment
                productionDepartment
            }
            reference {
                ...Reference
            }
        }
    }
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
    ${ReferenceFragment}
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteProduction($id: ID!) {
        deleteProduction(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const autoCreateQuery: DocumentNode = gql`
    mutation AutoCreateProduction($input: ProductionInput!) {
        autoCreateProduction(input: $input) {
            id
            reference {
                id
                eventId
                type
                uriPrefix
                deleted
            }
            errors {
                field
                message
            }
        }
    }
`;

export const saveActivity: DocumentNode = gql`
    mutation SaveActivity($productionId: String!, $planId: String!, $input: ActivityInput) {
        saveActivity(productionId: $productionId, planId: $planId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const activityDetails: DocumentNode = gql`
    query Activity($activityId: ID!) {
        activity(activityId: $activityId) {
            name
            uri
            activityType
            durationSpecified
            createdOn
            updatedOn
            activityStatus
            activityOwner {
                ...Reference
            }
            category {
                value
                lineage {
                    value
                }
            }
            identity {
                id
                preSave
            }
            dateRange {
                ...DateRange
            }
            locations {
                label
                primary
            }
            linkedResources {
                name
                type
                role
                status
                resourceCategory
                budgetCategory
                createdOn
                updatedOn
                identity {
                    id
                    preSave
                }
            }
            category {
                value
                lineage {
                    value
                }
            }
            dependencies {
                lag
                lagUnit
                type
                from {
                    id
                    eventId
                    uriPrefix
                    type
                    label
                    deleted
                }
                to {
                    id
                    eventId
                    uriPrefix
                    type
                    label
                    deleted
                }
            }
        }
    }
    ${DateRangeFragment}
    ${ReferenceFragment}
`;

export const deleteActivity: DocumentNode = gql`
    mutation DeleteActivity($id: ID!) {
        deleteActivity(id: $id) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const linkResourceToActivity: DocumentNode = gql`
    mutation LinkResourceToActivity($activityId: String!, $resourceId: String!) {
        linkResourceToActivity(activityId: $activityId, resourceId: $resourceId) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const unlinkResourceFromActivity: DocumentNode = gql`
    mutation UnlinkResourceFromActivity($activityId: String!, $resourceId: String!) {
        unlinkResourceFromActivity(activityId: $activityId, resourceId: $resourceId) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const createProductionFromTemplateMutation: DocumentNode = gql`
    mutation CreateProductionFromTemplate($templateId: ID!, $input: CreateProductionFromTemplateInput!) {
        createProductionFromTemplate(id: $templateId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveResourcesQuery: DocumentNode = gql`
    mutation SaveResources($productionId: String!, $input: [ResourceInput]!) {
        saveResources(productionId: $productionId, input: $input) {
            id
            reference {
                id
                type
                deleted
                eventId
            }
            errors {
                field
                message
            }
        }
    }
`;

export const saveResourceQuery: DocumentNode = gql`
    mutation SaveResource($productionId: String!, $input: ResourceInput!) {
        saveResource(productionId: $productionId, input: $input) {
            id
            reference {
                id
                type
                deleted
                eventId
            }
            errors {
                field
                message
            }
        }
    }
`;

export const resourceDetailsQuery: DocumentNode = gql`
    query Resource($resourceId: ID!) {
        resource(resourceId: $resourceId) {
            identity {
                id
            }
            name
            type
            role
            status
            resourceCategory
            budgetCategory
            summary {
                headline
                about
            }
            resourceSchedule {
                ...ScheduleFields
            }
            defaultNoteReference {
                id
                type
            }
            locations {
                ...LocationFields
            }
            linkedActivities {
                identity {
                    id
                }
                name
                activityType
                dateRange {
                    ...DateRange
                }
                locations {
                    ...LocationFields
                }
            }
            resourceProfile {
                attributeName
                value
            }
            reference {
                id
                type
                uriPrefix
                eventId
                deleted
            }
            associatedFieldDefinitionReferences {
                id
                type
                uriPrefix
                eventId
                deleted
            }
            attributes {
                name
                fieldType
                value
                fieldDefinitionReference {
                    id
                    type
                    uriPrefix
                    eventId
                    deleted
                }
            }
        }
    }
    ${LOCATION_FIELDS}
    ${SCHEDULE_FIELDS}
    ${DateRangeFragment}
`;

export const resourceDeleteQuery: DocumentNode = gql`
    mutation DeleteResource($id: ID!) {
        deleteResource(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addPersonToProduction: DocumentNode = gql`
    mutation AddPerson($productionId: ID!, $input: ManageContactInput) {
        saveAssociatedPerson(productionId: $productionId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addPeopleToProduction: DocumentNode = gql`
    mutation addAssociatedPeople($productionId: ID!, $input: [ContactInput]!) {
        saveAssociatedPeople(productionId: $productionId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const removePersonFromProduction: DocumentNode = gql`
    mutation RemovePerson($productionId: ID!, $input: ContactInput) {
        deleteAssociatedPerson(productionId: $productionId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const keyActivities: DocumentNode = gql`
    query KeyActivities($productionId: String!) {
        keyActivities(productionId: $productionId) {
            name
            uri
            activityType
            durationSpecified
            createdOn
            updatedOn
            identity {
                id
                preSave
            }
            dateRange {
                ...DateRange
            }
        }
    }
    ${DateRangeFragment}
`;

export const markKeyActivity: DocumentNode = gql`
    mutation MarkKeyActivity($activityId: String!) {
        markKeyActivity(activityId: $activityId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveKeyActivities: DocumentNode = gql`
    mutation SaveKeyActivities($planId: ID!, $input: [ReferenceInput]) {
        saveKeyActivities(planId: $planId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveKeyPeople: DocumentNode = gql`
    mutation SaveKeyPeople($productionId: ID!, $input: [ContactInput]) {
        saveKeyPeople(productionId: $productionId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveAttachmentQuery: DocumentNode = gql`
    mutation SaveAttachments($productionId: ID!, $input: [AttachmentInput]!) {
        saveAttachments(input: $input, productionId: $productionId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const productionAttachmentDetails: DocumentNode = gql`
    query Attachments($productionId: String!) {
        attachments(productionId: $productionId) {
            name
            size
            type
            createdOn
            createdBy
            entityReference {
                id
                eventId
                uriPrefix
                type
                label
                deleted
            }
        }
    }
`;

export const deleteAttachmentQuery: DocumentNode = gql`
    mutation DeleteAttachments($productionId: ID!, $input: [AttachmentInput]) {
        deleteAttachments(productionId: $productionId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const updateTaskMutation: DocumentNode = gql`
    mutation UpdateTaskAsDone($activityId: ID!, $isDone: Boolean!) {
        updateTaskAsDone(activityId: $activityId, isDone: $isDone) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const saveStorageAssetMutation: DocumentNode = gql`
    mutation SaveStorageAsset($productionId: ID!, $input: ManageStorageAssetInput) {
        saveStorageAsset(productionId: $productionId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const storageAssetsDetailsQuery: DocumentNode = gql`
    query StorageAssets($productionId: String!) {
        storageAssets(productionId: $productionId) {
            referenceName
            provider
            storageUrl {
                path
                tldomain
                subdomain
            }
        }
    }
`;

export const deleteStorageAssetMutation: DocumentNode = gql`
    mutation DeleteStorageAsset($productionId: ID!, $input: StorageAssetLinkInput) {
        deleteStorageAsset(productionId: $productionId, input: $input) {
            id
            errors {
                field
                key
                message
            }
            reference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export type ProductionInfo = {
    name: string;
    aka: string;
    productionType: string;
    productionStatus: string;
    productionGroup: ReferenceField;
    genre: Array<string>;
    managedProduction: boolean;
    keyPeople: any;
};

export type ProductionAboutInfo = {
    summary: {
        headline?: string;
        about?: string;
    };
    releaseDate: string;
    releaseYear: string;
    productionYears: string;
    showAttributes: Array<Record<any, any>>;
    locations: Array<Location>;
    budget: MoneyInput;
};

export type AssociatedOrganizations = {
    organizationReference: undefined;
    organization: '';
    productionRole: '';
    resourceCategory: '';
    categoryLineage: '';
};

export type ProductionBasicInfo = ProductionInfo & ProductionAboutInfo;

export const PRODUCT_TYPE_ICONS = {
    Animal: Icons.Animal,
    Cast: Icons.Cast,
    Crew: Icons.Crew,
    Equipment: Icons.Equipement,
    Place: Icons.Equipement,
    Product: Icons.Product,
    // Prop:
    Service: Icons.Services,
    Vehicle: Icons.Vehicle,
    Facility: Icons.Facility,
    // Wardrobe:
    Default: Icons.BuildCircleOutlined
};

export const toAssociatedOrgs = (orgs) => {
    return orgs.map((org) => {
        return {
            organizationReference: org.organizationReference,
            name: org.organization,
            organizationTypes: org.organizationTypes,
            organizationCategories: org.organizationCategories
        };
    });
};

export const toAssociatedPeople = (people, production = undefined) => {
    return people.map((p) => {
        const c: Contact = {
            name: p.name,
            contactInfo: p.contactInfo,
            personReference: p.personReference,
            role: p.role,
            organization: p.organization,
            organizationReference: p.organizationReference,
            productionDepartment: p.productionDepartment,
            organizationDepartment: p.organizationDepartment,
            title: p.title,
            productionRole: p.productionRole
        };
        if (production) {
            c.production = production.name;
            c.productionReference = {
                id: production.id
            };
        }
        return c;
    });
};

export const rowsToProduction = (rows, colDefs) => {
    const alternateIdTypes = colDefs.map((col) => {
        if (col.field.startsWith('AltId')) {
            return col.headerName;
        }
    });
    return rows.map((row) => {
        const productionEntity = {
            publicTitle: row.publicTitle,
            summary: {
                headline: '',
                about: row.summary
            },
            productionStatus: row.productionStatus,
            productionType: row.productionType,
            productionGroup: {
                label: row.productionGroup
            },
            releaseDate: row.releaseDate,
            productionYears: row.productionYears,
            genre: isEmpty(row.genre) ? [] : row.genre?.split(';'),
            alternateIds: [],
            showAttributes: []
        };
        if (row.ID) set(productionEntity, 'identity', { id: row.ID });
        if (row.otherTitles)
            set(productionEntity, 'otherTitles', [
                {
                    key: 'AKA',
                    value: row.otherTitles
                }
            ]);
        if (row.runtime) productionEntity.showAttributes.push({ key: 'runtime', value: row.runtime });
        if (row.seasonNo) productionEntity.showAttributes.push({ key: 'seasonNo', value: row.seasonNo });
        if (row.episodeNo) productionEntity.showAttributes.push({ key: 'episodeNo', value: row.episodeNo });
        if (row.noOfEpisodes) productionEntity.showAttributes.push({ key: 'noOfEpisodes', value: row.noOfEpisodes });
        alternateIdTypes.forEach((type) => {
            if (row[`AltId${type}`]) {
                const id = row[`AltId${type}`].split(';');
                productionEntity.alternateIds.push({
                    label: type,
                    value: id[0],
                    url: id[1]
                });
            }
        });
        return productionEntity;
    });
};

export const EMPTY_PRODUCTION = {
    headline: '',
    otherTitles: [],
    ...cloneDeep(EMPTY_PROD_INFO),
    ...cloneDeep(EMPTY_ABOUT_INFO),
    ...cloneDeep(EMPTY_ALT_IDS),
    ...cloneDeep(EMPTY_ASSOCIATED_PPL),
    ...cloneDeep(EMPTY_ASSOCIATED_ORGS),
    ...cloneDeep(EMPTY_PRODUCTION_DATES),
    ...cloneDeep(EMPTY_PROJECT_TRACK)
};
