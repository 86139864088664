import { gql } from '@apollo/client';

/**
 * These are additional fragments for individual entities as per individual requirements.
 * We can pass these within form widget search query, keeping basic fragment as it is and adding more fields as per individual requirement
 * NOTE: Any additional fragments a particular entity needs should be added to that entity's fragment.
 * For example Reservation also needs DateRange.
 */

export const OrganizationForShortlistFragment = gql`
    fragment OrganizationForShortlist on Organization {
        hasAccount
    }
`;

export const SharePersonInDirectoryFragment = gql`
    fragment SharePersonInDirectory on Person {
        isSharedInDirectory
    }
`;

export const ProductReferencesFragment = gql`
    fragment ProductReferences on Activity {
        productReferences {
            ...Reference
        }
    }
`;

export const ADDITIONAL_FRAGMENTS = {
    OrganizationForShortlist: OrganizationForShortlistFragment,
    SharePersonInDirectory: SharePersonInDirectoryFragment,
    ProductReferences: ProductReferencesFragment
};
