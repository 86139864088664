import { Box, SvgIcon, Typography } from '@mui/material';
import React from 'react';

export default ({ label = undefined, value = undefined, StartAdornment = <SvgIcon />, EndAdornment = <SvgIcon />, Value = undefined }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: '100%' }}>
            {StartAdornment}
            {(label || value) && (
                <Typography component={'div' as any} sx={{ fontSize: 'small', textOverflow: 'ellipsis' }}>
                    {label && <Box sx={{ fontWeight: 'bold', display: 'inline' }}>{label} : </Box>}
                    {value && value}
                </Typography>
            )}
            {Value && Value}
            {EndAdornment}
        </Box>
    );
};
