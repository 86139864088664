import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ResumeAdd = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 1.25C4.48122 1.25 3.25 2.48122 3.25 4V20C3.25 21.5188 4.48122 22.75 6 22.75H14.6659C14.4244 22.2825 14.2425 21.7791 14.1304 21.25H6C5.30964 21.25 4.75 20.6904 4.75 20V4C4.75 3.30964 5.30964 2.75 6 2.75H18C18.6904 2.75 19.25 3.30964 19.25 4V14.0464C19.4957 14.0158 19.746 14 20 14C20.254 14 20.5043 14.0158 20.75 14.0464V4C20.75 2.48122 19.5188 1.25 18 1.25H6Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M17.5305 14.5301C17.6661 14.3944 17.75 14.207 17.75 14C17.75 13.5858 17.4142 13.25 17 13.25H7C6.58579 13.25 6.25 13.5858 6.25 14C6.25 14.4142 6.58579 14.75 7 14.75H17C17.0362 14.75 17.0718 14.7474 17.1066 14.7425C17.2447 14.6663 17.3861 14.5955 17.5305 14.5301Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M14.6659 17.25H7C6.58579 17.25 6.25 17.5858 6.25 18C6.25 18.4142 6.58579 18.75 7 18.75H14.1304C14.2425 18.2209 14.4244 17.7175 14.6659 17.25Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25 5.5C7.25 4.25736 8.25736 3.25 9.5 3.25C10.7426 3.25 11.75 4.25736 11.75 5.5C11.75 6.3189 11.3125 7.03562 10.6585 7.4292L10.6926 7.44031C11.074 7.56669 11.4297 7.75484 11.7363 8.00013C12.0431 8.24558 12.2977 8.54598 12.4757 8.88979C12.6542 9.23457 12.75 9.61243 12.75 10C12.75 10.4142 12.4142 10.75 12 10.75C11.5858 10.75 11.25 10.4142 11.25 10C11.25 9.86228 11.2164 9.72 11.1437 9.57948C11.0704 9.43798 10.9567 9.29742 10.7992 9.17144C10.6416 9.0453 10.446 8.93881 10.2208 8.86417C9.99567 8.78957 9.75037 8.75 9.5 8.75C9.24963 8.75 9.00433 8.78957 8.7792 8.86417C8.55395 8.93881 8.35843 9.0453 8.20075 9.17144C8.04327 9.29742 7.92958 9.43798 7.85632 9.57948C7.78356 9.72 7.75 9.86228 7.75 10C7.75 10.4142 7.41421 10.75 7 10.75C6.58579 10.75 6.25 10.4142 6.25 10C6.25 9.61243 6.34577 9.23457 6.52428 8.88979C6.70229 8.54599 6.9569 8.24559 7.26371 8.00013C7.57033 7.75484 7.926 7.56669 8.30738 7.44031L8.34147 7.4292C7.68748 7.03562 7.25 6.3189 7.25 5.5ZM9.5 4.75C9.08579 4.75 8.75 5.08579 8.75 5.5C8.75 5.91421 9.08579 6.25 9.5 6.25C9.91421 6.25 10.25 5.91421 10.25 5.5C10.25 5.08579 9.91421 4.75 9.5 4.75Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M15 6.25C14.5858 6.25 14.25 6.58579 14.25 7C14.25 7.41421 14.5858 7.75 15 7.75H17C17.4142 7.75 17.75 7.41421 17.75 7C17.75 6.58579 17.4142 6.25 17 6.25H15Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M14.25 10C14.25 9.58579 14.5858 9.25 15 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H15C14.5858 10.75 14.25 10.4142 14.25 10Z"
                fill="currentColor"
                fillOpacity="1"
            />
            <path
                d="M23.5 20.5H20.5V23.5H19.5V20.5H16.5V19.5H19.5V16.5H20.5V19.5H23.5V20.5Z"
                fill="currentColor"
                fillOpacity="1"
            />
        </svg>
    </>,
    'ResumeAdd'
);
